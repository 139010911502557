// Define elements used to toggle modals active state
const utils = require('./utils');
const formUtil = require('./form-util');
const dealerships = require('./dealerships');
const MODAL_MAPPER = [
    {
        hash: /#book-a-service/g,
        modal: 'bookServiceModal'
    },
    {
        hash: /#parts-enquiry/g,
        modal: 'partsEnquiryModal'
    },
    {
        hash: /#general-enquiry/g,
        modal: 'generalEnquiryModal'
    },
    {
        hash: /#vehicle-enquiry/g,
        modal: 'VDPModal'
    },
    {
        hash: /#offer-enquiry/g,
        modal: 'offerEnquiryModal'
    },
    {
        hash: /#service-enquiry/g,
        modal: 'serviceRelatedEnquiryModal'
    },
    {
        hash: /#virtual-consultation/g,
        modal: 'virtualConsultationModal'
    },
    {
        hash: /#sales-appointment/g,
        modal: 'salesAppointmentModal'
    },
    {
        hash: /#sell-my-car/g,
        modal: 'sellMyCarModal'
    },
    {
        hash: /#product-genius/g,
        modal: 'productGeniusModal'
    },
    {
        hash: /#employee-program/g,
        modal: 'employeePartnerProgramModal'
    },
    {
        hash: /#update-details/g,
        modal: 'updateDetailsModal'
    },
    {
        hash: /#job-application/g,
        modal: 'jobApplicationModal'
    }
];

function initialiseFormDisplay(modal) {
    modal = $(modal);

    if (modal.find('form')[0]) {
        let formContainer = modal.find('.form__content');
        let form = modal.find('form');
        formUtil.resetForm(form);

        formContainer.removeClass('loading success failed');

        let previewFields = [
            'dealership-address',
            'dealership-phone',
            'department-open-hours'
        ];

        previewFields.forEach(field => {
            let formField = modal.find(`[${field}]`);
            if (formField) {
                formField.text('—')
            }
        });
    }

    modal.find('[show_text_area]').css('display', 'inline-flex');
    modal.find('.text__area-expand').addClass('text__area-display--none');
}

const toggleModal = function (modalId, href, options) {
    let $modal = $('#' + modalId);
    if (!$modal[0]) {
        $modal = $('#generalEnquiry');
    }
    let $navItems = $('.nav--mobile .nav__items');
    if ($navItems.css('display') === 'block') {
        $navItems.css('display', '');
    }

    let $modalBody = $modal.find('.modal');
    let $modalContent = $modal.find('.container');
    let $modalForm = $modal.find('form');
    let $iframe = $modal.find('iframe');
    let $spinner = $modal.find('#spinner__video');

    // Prevent toggle when loading
    if ($modal.find('[form-type]').hasClass('form--pending')) {
        return;
    }

    if (href) {
        $iframe[0].setAttribute("src", href);
    }

    if (options) {
        if (options.select) {
            setTimeout(function () {
                $modalForm.find('select[name="ENQUIRYTYPE"]').val(options.select);
            });
        }
        if (options.offer) {
            setTimeout(function () {
                $modalForm.find('[name="OFFERTITLE"]').val(options.offer.replace('%24', '$'));
            });
        }
        if (options.vehicleDescription) {
            setTimeout(function () {
                $modalForm.find('[name="VEHICLEDESCRIPTION"]').val(options.vehicleDescription);
            });
        }
    }

    // Active -> Hidden
    if ($modal.hasClass('active')) {
        let eventClose = new CustomEvent('closeModal', { 'detail': $modal });

        if (window.location.href.indexOf('#') > 0) {
            history.replaceState(null, null, window.location.href.split('#')[0]);
        }
        if ($spinner[0]) {
            $spinner.css('display', 'none');
        }

        if ($iframe[0]) {
            $iframe[0].setAttribute("src", '')
        }

        $modalBody.addClass('leave');
        $modal.addClass('fade-out');

        // Prevent stacking keyup event listeners
        $(document).off('keyup');
        utils.lockBody(false);
        window.dispatchEvent(eventClose);

        setTimeout(function () {
            $modal.removeClass('fade-out');
            $modal.removeClass('active');
            $modalBody.removeClass('leave');
            $modalBody.removeClass('active');

            initialiseFormDisplay($modal);
        }, 300);
    }
    // Hidden -> Active
    else {
        if ($spinner[0]) {
            $spinner.css('display', '');
        }
        // Ensure that the nav is closed when a modal is opened
        let $navToggle = $('.nav--mobile .nav__toggle');
        if ($navToggle.hasClass('active')) {
            utils.lockBody(false);
            utils.toggleActive($navToggle[0]);
            $navItems.css('height', '');
        }

        $modal.addClass('active');
        $modalBody.addClass('active');
        if ($modalContent[0]) {
            $modalContent[0].scrollTop = 0;
        }

        if ($modalBody[0]) {
            $modalBody[0].scrollTop = 0;
        }

        initialiseFormDisplay($modal);
        // Add dealership options to any select in this modal with an id of 'dealershipSelect'
        let dealershipSelectOptions = $modalForm.find('[dealership-select][options]');
        if (options || (dealershipSelectOptions && dealershipSelectOptions.length > 0)) {
            dealerships.setDealershipSelect($modalForm.find('[dealership-select]'), options);
        } else if (modalId === 'bookServiceModal') {
            dealerships.setDealershipSelect($modalForm.find('[dealership-select]'), {
                filters: {
                    field: 'departments.departmentName',
                    terms: 'Service'
                }
            });
        } else {
            dealerships.setDealershipSelect($modalForm.find('[dealership-select]'), {
                filters: {
                    field: 'emailSignatureDisplay',
                    terms: true
                }
            });
        }
        utils.lockBody(true);

        formUtil.focusFirst($modalForm);

        // Close the modal if the escape key is pressed
        $(document)
            .off('keyup')
            .on('keyup', function (e) {
                if (e.which === 27) {
                    toggleModal(modalId);
                }
            });
        if (modalId === 'serviceModal') {
            document.querySelector('[name="USERADDRESS"]').addEventListener('keypress', function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                }
            });
        }
    }
};

$(document)
    .ready(function () {
        $('[toggle-modal]')
            .on('click', function () {
                let href = this.getAttribute('data-video');
                toggleModal($(this).attr('toggle-modal'), href);
            });
        checkHash();
    });

window.onhashchange = function () {
    checkHash();
};

function checkHash() {
    MODAL_MAPPER.some(item => {
        if (window.location.hash.match(item.hash)) {
            const filters = JSON.parse(utils.urlParam('filters'));
            const select = utils.urlParam('select');
            const listAll = utils.urlParam('listAll');
            const offer = utils.urlParam('offer');
            const vehicleDescription = utils.urlParam('vehicleDescription');

            toggleModal(item.modal, null, { filters, select, listAll, offer, vehicleDescription });
            return true;
        }
    });
}

formUtil.catchSubmit($('[user-info-form]'), function (invalid) {
    if (!invalid) {
        toggleModal('serviceModal');
    }
});

// Stop propagation on modal. Prevent it from clicking the modal backdrop causing it to close.
$('.modal')
    .on('click', function (e) {
        e.stopPropagation();
    });

// Display a textarea
$('[show_text_area]')
    .on('click', function () {
        let $this = $(this);
        let $textarea = $this.parent().find('.text__area-expand');

        $this.css('display', 'none');
        $textarea.removeClass('text__area-display--none');
        $textarea.focus();
    });

let WORK_HOURS_MAPPER = [];

$('[dealership-select]').on('change', function () {
    const $form = $(this).closest('form');
    const $departmentWorkHours = $form.find('[department-open-hours]');
    WORK_HOURS_MAPPER = $departmentWorkHours.data('department-timetable');
    // Put sunday at the start of the week mapper
    WORK_HOURS_MAPPER ? WORK_HOURS_MAPPER.unshift(WORK_HOURS_MAPPER.pop()) : [];

    // Delay to allow the DOM to update
    setTimeout(() => {
        // Trigger a check to see if the selected time is valid
        $('[type="date"], [type="time"]').trigger('change');
    }, 1)
});

$('[type="date"], [type="time"]')
    .on('change', function () {
        const $form = $(this).closest('form');
        const $date = $form.find('[type=DATE]');
        const $time = $form.find('[type=TIME]');
        const $dateTime = $form.find('[name=DATETIME]');
        // const $dateFormatted = $form.find('[name=DATEFORMATTED]');
        const $warning = $form.find('.fieldset__warning');
        const $validDate = $warning.find('[name=validDate]');
        const $label = $warning.find('label');

        if ($date[0] && $time[0]) {
            let date = $date[0].valueAsDate;
            let time = $time[0].valueAsDate;

            if (date && time) {
                date.setHours(time.getUTCHours());
                date.setMinutes(time.getUTCMinutes());
                date.setSeconds(time.getUTCSeconds());

                if (WORK_HOURS_MAPPER.length > 0) {
                    const dateString = $date.val();
                    const timetable = WORK_HOURS_MAPPER[date.getDay()];
                    const currentDate = new Date();
                    const localTimezoneOffset = date.getISOTimezoneOffset();
                    const openTime = new Date(`${dateString}T${timetable.open}${localTimezoneOffset}`);
                    const closeTime = new Date(`${dateString}T${timetable.closed}${localTimezoneOffset}`);

                    if (date.isBefore(currentDate)) {
                        $validDate.val('');
                        $label.html('That date has already passed. Please select another date.').show();
                    } else if (!timetable.open && !timetable.closed) {
                        $validDate.val('');
                        $label.html('The Service department is closed on that day. Please select another date.').show();
                    } else if ($form.find('[name=FORMTYPE]').val() === 'Service Enquiry' && $form.find('[name=DEALERSHIPADDRESS]').val().indexOf('2 Link Road') > -1 && Date.parse($date.val()) > Date.parse("2022-06-30")) {
                        // Disables service bookings for Suttons City after 30th June 2022 (#22114/22248).
                        $validDate.val('');
                        $label.html('Elements of the Suttons City business are transitioning and relocating to other Suttons locations. Please visit <a class="label" analytics-event-catch analytics-event-category="' + document.querySelector('meta[property~="og:url"]')?.content + '" analytics-event-action="Suttons Service Page" analytics-event-label="bookServiceModal" href="https://www.suttons.com.au/suttons-service" target="_blank">www.suttons.com.au/suttons-service</a> for full details.').show();
                    } else if (!(date.isBetween(openTime, closeTime))) {
                        $validDate.val('');
                        $label.html('The Service department is closed at that time. Please select another time.').show();
                    } else {
                        $validDate.val('true');
                        $label.empty().hide();
                    }
                }

                $dateTime.val(
                    date.toLocaleString('en-AU', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                );

                $dateTime.data('date-string', $dateTime.val())
            } else {
                $dateTime.val($date.val() + ' ' + $time.val());
                $label.empty().hide();
            }
        } else {
            $label.empty().hide();
        }
    });

module.exports = {
    toggleModal: toggleModal,
    MODAL_MAPPER
};