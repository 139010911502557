let $window = $(window);
const utils = require('./utils');
let $links = $('.sub-nav-links').find('.link');


if ($('#inventory-car-details').length > 0) {
    let emailEnquiry = document.querySelector('#emailEnquiry');
    let emailEnquiryBtn = document.querySelector('#emailEnquiryBtn');
    let callDealership = document.querySelector('#callDealership');
    let callDealershipBtn = document.querySelector('#callDealershipBtn');

    let screenWidth = 812;
    if (callDealership) callDealership.style.display = 'none';

    let enquiryForm = document.querySelector('.VDP-enquiry-form');
    let parent = enquiryForm.parentNode;
    let overviewContainer = document.querySelector('#overviewScrollTo');
    let initVdpElements = () => {
        return ({
            finance: document.querySelector('#financeScrollTo'),
            endOfFinance: document.querySelector('#end-of-finance'),
            VDPEnquiryForm: enquiryForm,
            VDPEnquiryFormHeight: enquiryForm.offsetHeight,
            VDPEnquiryFormTop: parent.offsetTop,
            nav: document.querySelector('.nav'),
            navHeader: document.querySelector('.nav__header'),
            navUpper: document.querySelector('.nav__upper'),
            navToggle: document.querySelector('.nav__toggle'),
            parent: parent,
            header: document.querySelector('.header'),
            headerTitle: document.querySelector('.header__title'),
            overviewContainer: overviewContainer,
            formHeader: document.querySelector(".form__header"),
            form: document.querySelector(".form"),
            subNav: document.querySelector(".sub-nav"),
        })
    };

    let vdpElements = initVdpElements()

    // Set the global enquiry button to open Vehicle details page form
    $('#global_enquiry').attr('toggle-modal', 'VDPModal');

    $(callDealershipBtn).on('click', function () {
        emailEnquiry.style.display = 'none';
        callDealership.style.display = 'inherit';
    });

    $(emailEnquiryBtn)
        .on('click', function () {
            emailEnquiry.style.display = 'inherit';
            callDealership.style.display = 'none';
        });

    $window
        .on('resize', function () {
            window.requestAnimationFrame(() => {
                vdpElements = initVdpElements();
                let calcResult = {};
                let scrollTop = $window.scrollTop();
                if (window.innerWidth > screenWidth) {
                    calcResult = calc(vdpElements);
                    calcResult.scrollTop = scrollTop;
                    stick(vdpElements, calcResult);
                    activeScroll(calcResult, vdpElements);
                } else {
                    calcResult = calcMobile(vdpElements);
                    stickMobile(vdpElements, calcResult);
                    activeScroll(calcResult, vdpElements);
                }
            });
        });

    let lastScrollTop = 0;

    let handler = utils.throttle(function () {
        window.requestAnimationFrame(() => {
            let calcResult;
            let scrollTop = $window.scrollTop();
            if (window.innerWidth > screenWidth) {
                calcResult = calc(vdpElements);
                calcResult.scrollTop = scrollTop;
                stick(vdpElements, calcResult);
                activeScroll(calcResult, vdpElements);
            } else {

                let st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop && (st - lastScrollTop) < 132) {
                    let calcResult = calcMobile(vdpElements, true);
                    calcResult.scrollTop = scrollTop;
                    stickMobile(vdpElements, calcResult);
                    activeScroll(calcResult, vdpElements);
                } else {
                    let calcResult = calcMobile(vdpElements, false);
                    calcResult.scrollTop = scrollTop;
                    stickMobile(vdpElements, calcResult);
                    activeScroll(calcResult, vdpElements);
                }
                lastScrollTop = st;
            }
        });
    }, 20);

    document.addEventListener('scroll', handler, false);
    handler();
}

/**
 *
 * @returns {{finance: Element | null: *, endOfFinance: Element | null, VDPEnquiryForm: Element, nav: Element | null, navHeader: Element | null, parent: Node, header: Element | null, headerTitle: Element | null, overviewContainer: Element | null, formHeader: Element | null, form: Element | null, subNav: Element | null}}
 */


/**
 * Desktop functions
 */
function calc(vdpElements) {
    let navTop = parseInt(vdpElements.nav.style.top) ? parseInt(vdpElements.nav.style.top) : 0;
    let navUpper = vdpElements.navUpper ? vdpElements.navUpper.offsetHeight : 0;
    let navHeight = vdpElements.nav ? vdpElements.nav.offsetHeight + navTop : 0;
    return {
        // Calculate position when the form should be fixed
        pos1: vdpElements.header.offsetTop + vdpElements.header.offsetHeight - vdpElements.formHeader.offsetHeight - navHeight,
        // Calculate position when the sub nav should be fixed
        pos2: vdpElements.header.offsetTop + vdpElements.header.offsetHeight - navHeight,
        //Calculate position when the form footer should be fixed
        pos3: vdpElements.endOfFinance.offsetHeight + vdpElements.endOfFinance.offsetTop - vdpElements.VDPEnquiryFormHeight - navHeight,
        pos4: vdpElements.endOfFinance.offsetHeight + vdpElements.endOfFinance.offsetTop - vdpElements.formHeader.offsetHeight - navHeight,
        navTop,
        navHeight,
        navUpper,
        // Calculate width
        width: vdpElements.VDPEnquiryForm.offsetWidth
    }
}

function stick(vdpElements, calculations) {
    vdpElements.overviewContainer.style.marginTop = '';
    vdpElements.VDPEnquiryForm.style.position = 'absolute';
    //default form position
    if (calculations.scrollTop >= calculations.pos4) {
        vdpElements.formHeader.style.top = vdpElements.VDPEnquiryForm.offsetHeight - vdpElements.formHeader.offsetHeight + 'px';
        vdpElements.formHeader.style.position = 'absolute';
        vdpElements.formHeader.style.width = 100 + '%';
    }

    if (calculations.scrollTop <= calculations.pos1) {
        vdpElements.VDPEnquiryForm.style.top = '';
        vdpElements.VDPEnquiryForm.style.position = '';
        vdpElements.formHeader.style.position = '';
        vdpElements.formHeader.style.top = '';
        vdpElements.formHeader.nextElementSibling.style.marginTop = '';
        vdpElements.VDPEnquiryForm.style.width = '';
        vdpElements.formHeader.style.width = '';
    }
    //When the form header should be sticky
    else if (calculations.scrollTop > calculations.pos1 && calculations.scrollTop < calculations.pos3) {
        vdpElements.VDPEnquiryForm.style.position = 'fixed';
        vdpElements.VDPEnquiryForm.style.top = calculations.navHeight + 'px';
        vdpElements.VDPEnquiryForm.style.width = 'calc((' + 100 + '% / ' + 12 + ') * ' + 4 + ' - 2.65rem)';
        vdpElements.VDPEnquiryForm.style.maxWidth = 357.328 + 'px';
        vdpElements.formHeader.style.position = '';
        vdpElements.formHeader.style.top = '';
        vdpElements.formHeader.nextElementSibling.style.marginTop = '';
        vdpElements.formHeader.style.width = '';

    }
    // When the form footer should be sticky to the bottom
    else if (calculations.pos4 > calculations.scrollTop && calculations.scrollTop >= calculations.pos3) {
        vdpElements.formHeader.nextElementSibling.style.marginTop = vdpElements.formHeader.offsetHeight + 'px';
        vdpElements.VDPEnquiryForm.style.width = '';
        vdpElements.formHeader.style.width = 'calc' + '((' + 100 + '%' + ' / ' + 12 + ')' + ' * ' + 4 + ' - ' + '2.65rem' + ')';
        vdpElements.formHeader.style.maxWidth = 357.328 + 'px';
        vdpElements.formHeader.style.zIndex = '10';
        vdpElements.formHeader.style.position = 'fixed';
        vdpElements.formHeader.style.top = calculations.navHeight + 'px';
        vdpElements.VDPEnquiryForm.style.top = calculations.pos3 - vdpElements.overviewContainer.offsetTop - vdpElements.VDPEnquiryFormTop + calculations.navUpper + 16 + 'px';
    }
    // When the sub nav should be sticky to the top of the page
    if (calculations.scrollTop >= calculations.pos2) {
        vdpElements.subNav.style.position = 'fixed';
        vdpElements.subNav.style.top = calculations.navHeight + 'px';
        if (vdpElements.overviewContainer) {
            vdpElements.overviewContainer.style.marginTop = 48 + 'px';
        }
    } else if (calculations.scrollTop < calculations.pos2) {
        if (vdpElements.overviewContainer) {
            vdpElements.overviewContainer.style.marginTop = '';
        }
        vdpElements.subNav.style.position = '';
        vdpElements.subNav.style.top = 48 + 'px';
    }
}


/**
 * Mobile functions
 */
function calcMobile(vdpElements, isScrollUp) {
    let navHeaderHeight = vdpElements.navHeader ? vdpElements.navHeader.offsetHeight : 0;
    let navToggle = vdpElements.navToggle ? vdpElements.navToggle.offsetHeight : 0;
    if (isScrollUp) {
        return {
            pos2: vdpElements.overviewContainer.offsetTop - vdpElements.subNav.offsetHeight - navHeaderHeight + navToggle,
            pos3: vdpElements.finance.offsetTop + vdpElements.finance.offsetHeight - window.innerHeight,
            pos4: vdpElements.finance.offsetTop + vdpElements.finance.offsetHeight - vdpElements.subNav.offsetHeight,
            subNavHeight: vdpElements.subNav.offsetHeight,
        }
    } else {
        return {
            pos2: vdpElements.overviewContainer.offsetTop - vdpElements.subNav.offsetHeight - navHeaderHeight,
            pos3: vdpElements.finance.offsetTop + vdpElements.finance.offsetHeight - window.innerHeight,
            pos4: vdpElements.finance.offsetTop + vdpElements.finance.offsetHeight - navHeaderHeight - vdpElements.subNav.offsetHeight,
            subNavHeight: vdpElements.subNav.offsetHeight,
        }
    }
}

function stickMobile(vdpElements, calculations) {
    let navHeaderHeight = vdpElements.navHeader ? vdpElements.navHeader.offsetHeight : 0;
    if (vdpElements.overviewContainer) {
        vdpElements.overviewContainer.style.marginTop = '';
    }
    if (calculations.scrollTop >= calculations.pos2) {
        vdpElements.subNav.style.marginBottom = '';
    } else if (calculations.scrollTop < calculations.pos2) {
        vdpElements.subNav.style.position = 'relative';
        vdpElements.subNav.style.top = '';
        vdpElements.overviewContainer.style.marginTop = '';
        vdpElements.subNav.classList.remove('transition');
        vdpElements.subNav.style.transform = "translate3d(0, 0, 0)";
    }

    if (calculations.scrollTop >= calculations.pos2 && calculations.scrollTop <= calculations.pos4) {
        vdpElements.subNav.style.position = 'fixed';
        vdpElements.subNav.style.top = navHeaderHeight + 'px';
        vdpElements.overviewContainer.style.marginTop = calculations.subNavHeight + 'px';
        vdpElements.subNav.style.transform = "";

        setTimeout(function () {
            vdpElements.subNav.classList.add('transition');
        }, 1)
    } else if (calculations.scrollTop > calculations.pos4) {
        vdpElements.subNav.style.transform = "translate3d(0, 0, 0)";
        vdpElements.subNav.classList.remove('transition');
        vdpElements.subNav.style.position = 'absolute';
        vdpElements.subNav.style.top = calculations.pos3 + window.innerHeight - calculations.subNavHeight + 'px';
    }
}

function activeScroll(calculations, vdpElements) {
    $links.each(function (index, el) {
        let navHeight = vdpElements.nav ? vdpElements.nav.offsetHeight : 0;
        let $link = $(el);
        let $anchor = $('#' + $link.attr('id') + 'ScrollTo');

        if ($anchor && $anchor.length > 0 && (calculations.scrollTop >= $anchor.offset().top - navHeight - vdpElements.subNav.offsetHeight && calculations.scrollTop < $anchor.offset().top - navHeight - vdpElements.subNav.offsetHeight + $anchor.offset().height)) {
            $link.css('color', '#000000');
        } else {
            $link.css('color', '');
        }
    });
}