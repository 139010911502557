$(document)
    .ready(function () {
        if ("ontouchstart" in document.documentElement) {
            $('body').addClass('not-hover')
        }

        let $coverImage = $("#cover-image--mobile");

        if ($coverImage.offset()) {
            $("#scrollTo").addClass("fade-in--up");
            let imageBottom = $coverImage.offset().top + $coverImage.height();
            $(window).scroll(function () {
                if ($(window).scrollTop() + window.innerHeight > imageBottom + 100) { //scrolled past the image
                    $("#scrollTo").addClass("fade-out--down").removeClass("fade-in--up");
                } else {
                    $("#scrollTo").addClass("fade-in--up").removeClass("fade-out--down");
                }
            });
        }
    });