const utils = require('./utils');

if (document.querySelector('#carousel_nav')) {
    let $container = $('#carousel_nav');
    const screenMobile = 812;
    let $window = $(window);

    const elements = {
        stickStart: document.querySelector('.nav-carousel-stick-start') || document.querySelector('.nav'),
        stickEnd: document.querySelector('.nav-carousel-stick-end') || document.querySelector('.footer'),
        carouselNav: $container.next('.nav-carousel-space')[0],
    };

    const calc = () => {
        let mobile = window.innerWidth < screenMobile;
        let isNavDown = elements.stickStart.classList.contains('slideInDown');
        let navHeight = elements.stickStart.offsetHeight + elements.stickStart.offsetTop - 1;
        let navPos = isNavDown || !mobile ? navHeight : 0;

        return {
            scrollTop: $window.scrollTop(),
            navHeight: navHeight,
            navPos: navPos,
            subNavHeight: elements.carouselNav?.offsetHeight,
            finalStickPos: elements.stickEnd?.offsetTop - elements.carouselNav?.offsetHeight,
            pos1: elements.carouselNav?.offsetTop - navPos,
            pos2: elements.stickEnd?.offsetTop - elements.carouselNav?.offsetHeight - navPos
        }
    };

    const stickNav = (calculations) => {
        let navCarousel = $container.find('.nav-carousel-container')[0];
        // Must be referencing a static value
        if (calculations.scrollTop <= calculations.pos1) {
            navCarousel.style.top = '';
            navCarousel.style.position = '';
            navCarousel.classList.remove('transition');
            navCarousel.style.transform = "translate3d(0, 0, 0)";
        } else if (calculations.scrollTop > calculations.pos2) {
            navCarousel.style.top = calculations.finalStickPos + 'px';
            navCarousel.style.position = 'absolute';
            navCarousel.classList.remove('transition');
            navCarousel.style.transform = "translate3d(0, 0, 0)";
        } else if (calculations.scrollTop > calculations.pos1) {
            navCarousel.style.top = calculations.navHeight + 'px';
            navCarousel.style.position = 'fixed';
            navCarousel.style.transform = "";

            setTimeout(function () {
                navCarousel.classList.add('transition');
            }, 1)
        }
    };

    let handler = utils.throttle(function () {
        window.requestAnimationFrame(function () {
            stickNav(calc());
            activeScroll(calc());
        });
    }, 20);

    $window.on('resize', function () {
        stickNav(calc());
    });

    document.removeEventListener('scroll', handler, false);
    document.addEventListener('scroll', handler, false);
    handler();
}


function activeScroll(calculations) {
    let $navItems = $('.nav-carousel').find('.nav-swiper');

    $navItems.each(function (index, el) {
        let $link = $(el);
        if (el.hasAttribute('scrollTo')) {
            let $anchor = $('#' + $link.attr('scrollTo'));

            if (calculations.scrollTop >= $anchor.offset().top - calculations.navPos - calculations.subNavHeight && calculations.scrollTop < $anchor.offset().top - calculations.navPos - calculations.subNavHeight + $anchor.offset().height) {
                $link.addClass('active');
                const switchSlide = new CustomEvent('switchSlide', {
                    detail: {
                        id: $link.closest('.swiper-container').attr('id'),
                        slideNo: index
                    }
                });
                window.dispatchEvent(switchSlide);
            } else {
                $link.removeClass('active');
            }
        }
    });
}
