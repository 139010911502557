module.exports = {
    trackEventByElementAttributes: trackEventByElementAttributes,

    trackGoogleEvent: trackGoogleEvent,
    trackGoogleTiming: trackGoogleTiming,

    trackFacebookRegistration: trackFacebookRegistration,
    trackFacebookLead: trackFacebookLead
};

// Send a timing event when the document has completely loaded
$(document).ready(function () {
    trackGoogleTiming('page', 'load', Date.now() - window.pageLoadStart, window.location.pathname);
});

// Analytics tracking nav events in footer
const footerLinks = $('.footer a');
footerLinks.on('click', function () {
    trackNavAndFooterActions($(this), 'footer')
});

// Analytics tracking nav events
const navLinks = $('.nav a');
navLinks.on('click', function () {
    trackNavAndFooterActions($(this), 'navbar')
});

// Define elements which will trigger analytics events
const googleAnalyticsEventCatch = $('[analytics-event-catch]');
googleAnalyticsEventCatch.on('click', function () {
    trackEventByElementAttributes($(this));
});

const pdfLinks = $('.card--pdf');
pdfLinks.on('click', function () {
    let el = $(this);
    let hrefBlocks = el.attr('href').split('/');
    trackGoogleEvent('pdf', 'open', hrefBlocks[hrefBlocks.length - 1]);
});


/**
 * Send the appropriate events  to google analytics from the navbar and footer
 *
 * @param el
 * @param category
 */
function trackNavAndFooterActions(el, category) {
    let href = el.attr('href');
    let toggleModal = el.attr('toggle-modal');

    if (href) {
        trackGoogleEvent(category, 'navigation', href);
    }

    if (toggleModal) {
        trackGoogleEvent(category, 'open modal', toggleModal);
    }
}

/**
 * Send an analytics event when an element is clicked
 *
 * @param el - The element to use attribute properties for analytics tracking
 *
 * @attr analytics-event-catch
 * @attr analytics-event-category
 * @attr analytics-event-action
 * @attr analytics-event-label
 * @attr analytics-event-value
 * @attr analytics-event-nonInteraction
 */
function trackEventByElementAttributes(el) {
    let category = el.attr('analytics-event-category');
    let action = el.attr('analytics-event-action');
    let label = el.attr('analytics-event-label');
    let value = el.attr('analytics-event-value');
    let nonInteraction = el.attr('analytics-event-nonInteraction');

    trackGoogleEvent(category, action, label, value, nonInteraction);

    // Track click to call (lead) events with facebook
    if ((action === 'click to call') || (action === 'click_to_call')) {
        trackFacebookLead();
    }
}

/**
 * Send an event
 *
 * Reference: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 *
 * @param category - Typically the object that was interacted with (e.g. 'Video')
 * @param action - The type of interaction (e.g. 'play')
 * @param label - Useful for categorizing events (e.g. 'Fall Campaign')
 * @param nonInteraction - nonInteraction events will not count towards bounce rate
 */
function trackGoogleEvent(category, action, label, nonInteraction) {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', action.replace(/ /g, '_'), {
            'event_category': category,
            'event_label': label
        });
    } else if (window.ga) {
        window.ga('send', {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            nonInteraction: nonInteraction
        })
    }
}

/**
 * Send a time
 *
 * Reference: https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings
 *
 * @param category - A string for categorizing all user timing variables into logical groups (e.g. 'JS Dependencies').
 * @param variable - A string to identify the variable being recorded (e.g. 'load').
 * @param value - The number of milliseconds in elapsed time to report to Google Analytics (e.g. 20).
 * @param label - A string that can be used to add flexibility in visualizing user timings in the reports (e.g. 'Google CDN').
 */
function trackGoogleTiming(category, variable, value, label) {
    if (window.ga) {
        window.ga('send', {
            hitType: 'timing',
            timingCategory: category,
            timingVar: variable,
            eventValue: value,
            timingLabel: label
        })
    }
}

/**
 * Submit a facebook complete registration event
 * Reference: https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.11
 *
 * @param value - Add a variable or static value. The value set must be a decimal number greater than or equal to zero.
 *                It may not include letters, special characters, currency symbols or commas.
 */
function trackFacebookRegistration(value) {
    if (window.fbq) {

        // Format value for FB Pixel as it does not like a null value
        value = (value) ? {value: value} : null;
        window.fbq('track', 'CompleteRegistration', value);
    }
}

/**
 * Submit a facebook lead event
 *
 * @param value - Add a variable or static value. The value set must be a decimal number greater than or equal to zero.
 *                It may not include letters, special characters, currency symbols or commas.
 */
function trackFacebookLead(value) {
    if (window.fbq) {

        // Format value for FB Pixel as it does not like a null value
        value = (value) ? {value: value} : null;
        window.fbq('track', 'Lead', value);
    }
}