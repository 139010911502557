const $button = document.querySelector('.button--404');
const baseURL = window.location.protocol + "//" + window.location.host + "/";

initButton();

function goBack() {
    if (document.referrer.indexOf(baseURL) != -1) {
        return history.back();
    } else {
        document.location.href = "/";
    }
}

function initButton() {
    if ($button) {
        $button.addEventListener('click', function () {
            goBack();
        });

        if (document.referrer.indexOf(baseURL) != -1) {
            $button.innerText = 'Go Back';
        } else {
            $button.innerText = 'Back to homepage';
        }
    }
}
