const isIE = navigator.userAgent.indexOf('MSIE') !== -1
    || navigator.appVersion.indexOf('Trident/') > 0;

if (isIE) {
    $(document).ready(() => {
        pictureFill();
    });


    $(window).on('resize', () => {
        pictureFill();
    });
}

function pictureFill() {
    $('picture').each(function () {
        let $container = $(this);
        let imgSrc = $container.find('source');
        let imgUrl = '';
        imgSrc.each((i, src) => {
            if (window.innerWidth > $(src).attr('value')) {
                if ($(src).attr('srcset')) {
                    imgUrl = $(src).attr('srcset').split(' 1x,')[0].trim();
                } else if ($(src).attr('data-srcset')) {
                    imgUrl = $(src).attr('data-srcset').split(' 1x,')[0].trim();
                } else if ($(src).attr('data-iesrc')) {
                    imgUrl = $(src).attr('data-iesrc').trim();
                }
            }
        });
        if (imgUrl) {
            setTimeout(function () {
                $container
                    .css('background-image', 'url("' + imgUrl + '") !important')
                    .css('opacity', 1)
                    .addClass('compat-object-fit');
            }, 100)
        }
    });
}