const observer = require('../lozad');
const googleMaps = require('../google-maps');
const {constructSwipers} = require('../carousel');
const {scrollFrame} = require("../smooth-scroll");
const inventoryFilters = require("../inventory/filters");
const {initFinanceCalculator} = require("../taurus-finance-calculator");

let componentObserver = null;

function getComponents() {
    const components = [];
    const startIndex = $('body > .content .components_start').index();
    const endIndex = $('body > .content .components_end').index();
    $('body > .content > *')
        .each((index, element) => {
            if (index <= startIndex || index >= endIndex) return;

            const $element = $(element);
            if ($element.attr('id')) {
                components.push($element);
            }
        });

    return components;
}

function init(origin, noEditState) {
    if (noEditState) {
        $('body').addClass('u-pointer-events-none');
    } else {
        $('nav').addClass('u-pointer-events-none');
        $('footer').addClass('u-pointer-events-none');
    }

    getComponents()
        .forEach(($element) => {
            initComponent($element, origin);
        });

    // initScrollWatcher(origin);
}

function initComponent($element, origin) {
    $element.addClass('cms-component');

    disableUserInteraction($element);
    initEditButton($element, origin);
}

function initEditButton($element, origin) {
    const id = $element.attr('id');

    // Create an edit button for each registered component
    const $edit = $(`
                <button type="button" class="cms-button cms-button--edit m-2">
                    <span class="cms-button-icon--edit"></span>
                    <span>Edit</span>
                </button>
            `.trim());

    // Post a message when the edit button is clicked for this component
    $edit
        .off('click')
        .on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.parent.postMessage(`__preview__edit::${id}`, origin);
        });

    $element.append($edit);
}

// function initScrollWatcher(origin) {
//     const $components = getComponents();
//     if (!componentObserver) {
//         let previous = null;
//
//         componentObserver = new IntersectionObserver((entries) => {
//             entries.forEach((entry) => {
//                 if (entry.isIntersecting) {
//                     if (!previous) {
//                         var first = $components.first()[0];
//                         if (first) {
//                             window.parent.postMessage(`__preview__active::${first.id}`, origin);
//                             previous = entry.target;
//                         } else {
//                             previous = null;
//                         }
//
//                     } else if (previous !== entry.target) {
//                         const next = $(entry.target).prev()[0];
//                         if (next) {
//                             window.parent.postMessage(`__preview__active::${next.id}`, origin);
//                             previous = next;
//                         } else {
//                             previous = null;
//                         }
//                     }
//                 }
//             });
//         }, {
//             threshold: 0
//         });
//     }
//
//     componentObserver.disconnect();
//     $components.each((index, sec) => componentObserver.observe(sec));
// }

function scrollToComponent($component) {
    const $nav = $('.nav');
    let to = $component.offset().top + 4;

    // Handle if there is a nav and offset it
    if ($nav.length) {
        const navOffset = $nav.offset();

        // Using `nav-spacing` offset top because the nav top moves when scrolling
        to -= navOffset.height;
    }

    scrollFrame(to, 50);
}

function disableUserInteraction(scope) {
    $(scope).children().each(function () {
        const $this = $(this);
        if (!$this.hasClass('cms-button')) {
            $this.addClass('u-pointer-events-none');
        }
    });
}

const messageHandler = (e) => {
    if (typeof e.data !== 'string') {
        return;
    }
    if (e.data.indexOf('__preview__') === 0 && e.origin !== window.origin) {
        const parts = e.data.split('__preview__')[1].split('::');
        const action = parts[0];
        const value = (parts[1] || '').indexOf('{') === 0 || (parts[1] || '').indexOf('[') === 0 ? JSON.parse(parts[1]) : parts[1];

        switch (action) {
            case 'handshake': {
                // When a block has invalid html the components end element may be rendered in body rather than the
                // content container. This ensures that the components end element is inside the content container.
                $('body > .components_end').detach().appendTo($('body > .content'));

                const hasComponentsStart = $('body > .content .components_start').length > 0;
                window.parent.postMessage(`__preview__handshake_received::${hasComponentsStart}`, e.origin);
                break;
            }
            case 'handshake_received': {
                init(e.origin, value);
                break;
            }
            case 'add':
            case 'change': {
                // Make a request to get the new component html and replace it with the one that is there
                $.ajax({
                    type: 'POST',
                    url: `/cms/components/${value.type}/preview`,
                    contentType: "application/json; charset=utf-8",
                    data: JSON.stringify(value),
                    success: function (data) {
                        const $data = $(data);
                        const $existing = $(`#${value.id}`);

                        // Upsert the component onto the page
                        if (!$existing.length) {
                            const $components = getComponents();
                            if (!$components.length) {
                                $('body > .content > .components_start').after($data);
                            } else if (value.index === $components.length) {
                                $('body > .content > .components_end').before($data);
                            } else  {
                                $($components[value.index]).before($data);
                            }
                        } else {
                            $existing.replaceWith($data);
                        }

                        initComponent($data, e.origin);
                        // initScrollWatcher(e.origin);

                        // Initialise any lazy loading images
                        if ($data.find('.lozad').length) {
                            observer.observe();
                        }

                        // Initialise any carousels
                        if ($data.find('.swiper-container').length) {
                            constructSwipers(
                                Array.prototype.slice.call(
                                    $data[0].querySelectorAll('.swiper-container:not(.carousel__nav)')
                                )
                            );
                        }

                        // Initialise any google maps
                        if ($data.find('.map').length) {
                            googleMaps.initGoogleMaps();
                        }

                        // Initialise inventory list
                        if ($data.find('#inventory_container').length) {
                            inventoryFilters.init(true);
                        }

                        // Initialise finance calculator
                        if ($data.find('.taurus-finance-calculator').length) {
                            initFinanceCalculator(true);
                        }

                        // After adding the component scroll to it
                        if (action === 'add') {
                            scrollToComponent($data);
                        }
                    },
                    error: function (xhr, errorType, error) {
                        console.log(xhr, errorType, error);
                    }
                });
                break;
            }
            case 'delete': {
                $(`#${value}`).remove();
                // initScrollWatcher(e.origin);
                break;
            }
            case 'sort-order-changed': {
                let $from = null;
                let $to = null;
                getComponents()
                    .forEach((element, index) => {
                        if (value.oldIndex === index) {
                            $from = $(element);
                        }
                        if (value.newIndex === index) {
                            $to = $(element);
                        }
                    });

                if ($from && $to) {
                    if (value.oldIndex < value.newIndex) {
                        $to.after($from);
                    } else if (value.oldIndex > value.newIndex) {
                        $to.before($from);
                    }
                }

                // initScrollWatcher(e.origin);

                break;
            }
            case 'go-to': {
                const $elem = $("#".concat(value));
                if ($elem.length) {
                    scrollToComponent($elem);
                }
            }
        }
    }
}

$(window).on("message", messageHandler);
$(document)
    .ready(() => {
        window.parent.postMessage('__preview__handshake', '*');
    });