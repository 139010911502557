const $locationPicker = $('section [name="service_with_us"]');
const $tabs = $('[name="service_with_us"] .tab');
const $dataSections = $('[data-location]');

if ($locationPicker && $locationPicker.length > 0) {
    const hideSections = () => {
        $dataSections.forEach((section) => {
            let $section = $(section);
            $section.css('display', 'none');
        });
        window.dispatchEvent(new Event('resize'));
    };

    const updateSections = (code) => {
        hideSections();
        let $sections = $('[data-location="' + code + '"]');
        $sections.forEach((section) => {
            let $section = $(section);
            $section.css('display', '');
        });
    };

    $locationPicker.on('change', function () {
        updateSections($(this).val());
    });

    $tabs.on('click', function () {
        updateSections($(this).data('id'));
    });

    setTimeout(() => {
        hideSections();
        $locationPicker.trigger('change');
    });
}
