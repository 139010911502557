const axios = require("axios");
const _set = require('lodash/set');

module.exports = {
    resetForm,
    validateForm,
    catchSubmit,
    validateInputsOnChange,
    focusFirst,
    serializeObject,
    upload
};

/**
 * Clears form inputs and sets class validation to a pristine state
 *
 * @param form - Form to reset
 */
function resetForm(form) {
    form = $(form);
    let formContainer = $(form[0].parentNode);
    let $dealershipSelect = formContainer.find('select[name=dealershipId]');

    // Reset the form inputs
    form[0].reset();

    // Resets dealership dropdown if it's not hidden
    if ($dealershipSelect && $dealershipSelect.length > 0 && !$dealershipSelect.attr('hidden')) {
        let hiddenDealershipId = formContainer.find('input[name=dealershipId]');
        hiddenDealershipId.val('');
    }

    formContainer
        .removeClass('form--pending')
        .removeClass('form--success')
        .removeClass('form--failed');

    form.removeClass('submitted');
    // Clear validation classes
    form.find('.dirty, invalid', function (input) {
        $(input).removeClass('dirty').removeClass('invalid').addClass('pristine');
    });
}

/**
 * Convert the form data (through serializeArray) into an object with prop as the input name and value as the input value
 *
 * @param form - The form to serialise
 */
function serializeObject(form) {
    let $form = $(form);
    // Convert the serialize array into an object with prop as the input name and value as the input value
    let body = {};
    $form.serializeArray().forEach(input => _set(body, input.name, input.value || '—'));

    return body;
}

/**
 * Catches a submit function, sets form validation classes and calls an onSuccess or onFail function depending on if
 * the form validation passed.
 *
 * onSuccess receives the form submitted and an object of all form inputs as {name: value, .., ..}
 * onFail receives the form submitted
 *
 * @param forms - Form to catch submits
 * @param done - Callback for when the form is valid of invalid
 */
function catchSubmit(forms, done) {
    $(forms).on('submit', function (e) {
        e.preventDefault();

        let $form = $(this);
        if (validateForm($form)) {
            done(null, serializeObject($form));
        } else {
            $form.addClass('submitted');

            focusFirst($form, true);

            done(true);
        }
    })
}

/**
 * Loops through all form inputs and selects
 *
 * @param form - The form to validate
 * @returns {boolean} - True if all inputs and selects are valid
 */
function validateForm(form) {
    let valid = true;

    $(form).find('input, select, textarea').forEach(function (input) {
        input = $(input);

        // Make the inputs, pristine
        input.removeClass('dirty').addClass('pristine');

        if (!input[0].validity.valid) {
            valid = false;
            input.addClass('invalid');
        } else {
            input.removeClass('invalid');
        }
    });

    return valid;
}

/**
 * Loops through all form inputs and selects adding class validation when they change.
 *
 * dirty - the input has been changed since the last form reset / submit
 * pristine - the input has NOT been changed since the last form reset / submit
 * invalid - the input is invalid
 *
 * @param form - the form to validate inputs on change
 */
function validateInputsOnChange(form) {
    $(form).find('input, select, textarea').forEach(function (input) {
        input = $(input);

        input.on('change', function () {
            // Set dirty
            input.addClass('dirty').removeClass('pristine');

            // Set validity
            if (!input[0].validity.valid) {
                input.addClass('invalid');
            }
        })
    });
}

/**
 * Focuses the first form input field.
 *
 * @param element The container element to look for input elements with in.
 * @param {boolean=} invalid True to focus the first invalid field.
 */
function focusFirst(element, invalid) {
    setTimeout(function () {
        let selector = !invalid ? 'input:not([type=hidden]), select, textarea' : '.invalid';
        let $firstInvalid = element.find('fieldset').find(selector).first();
        $firstInvalid.focus();

        if ($firstInvalid[0] && /text|search|password|tel|url/i.test($firstInvalid[0].type)) {
            $firstInvalid[0].setSelectionRange(0, $firstInvalid.val().length);
        }
    }, 0);
}

/**
 * Upload all files if any before submitting the form.
 *
 * @param files Files to upload
 * @param body Form body
 */
async function upload(files, body) {
    return new Promise(async (resolve, reject) => {
            // If no files to upload skip this and continue the form submission.
            if (!files || files.length === 0) resolve(body);
            for (const file of files) {
                let data = new FormData();
                data.append('file', file.files[0]);
                data.append('action', 'upload');
                data.append('private', true);
                const uuid = file.getAttribute('data-dealership');
                await axios.post(`/api/v2/attachments/${uuid}`, data).then(({data}) => {
                    const name = file.getAttribute('name');
                    const url = new URL(data[0]._links.download.href);
                    body[name] = url.pathname;
                    resolve(body)
                }).catch((err) => {
                    if (err.response) reject(err.response);
                    reject(err);
                });
            }

        }
    );
}