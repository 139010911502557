const modals = require('./modals');
const $popUps = $('[pop-up]');

const checkPopUp = (id, cookie) => {
    if (cookie) {
        let popUp = getCookie(`popUp_${id}`);
        if (!popUp) {
            modals.toggleModal(id);
            setCookie(`popUp_${id}`, true);
        }
    } else {
        modals.toggleModal(id);
    }
}

const setCookie = (cookieName, cookieValue, days) => {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

$(document).ready(() => {
    if ($popUps && $popUps.length > 0) {
        $popUps.forEach((popUp) => {
            let $popUp = $(popUp);
            let cookie = !!($popUp.attr('cookie') && $popUp.attr('cookie').length > 0);
            checkPopUp($popUp.attr('pop-up'), cookie);
        })
    }
})