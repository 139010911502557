const $filterType = $('[filtertype]');
const filterType = ($filterType && $filterType.length > 0) ? $filterType.attr('filtertype') : 'cars';
const _filter = require('lodash/filter');

let makes = [];
const $vehicleSearchTool = $("#vehicle-search-tool")
const $vehicleTabs = $vehicleSearchTool.find('.tab');

const populateMakeField = function (callback) {
    let data = {
        body: {
            query: {
                bool: {
                    must: []
                }
            },
            aggs: {
                make_bucket: {
                    terms: {
                        field: "make",
                        size: 1000
                    }
                },
            }
        }
    };

    $.ajax({
        type: 'POST',
        url: '/inventory/' + filterType + '/filter',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        ifModified: true,
        success: function (body) {
            if (body.aggregations) {
                makes = body.aggregations.make_bucket.buckets;
                setupMakeSection($('.filter__section--make'));
            }

            if (callback) {
                callback();
            }
        },
        error: callback
    });
}

const setupMakeSection = function ($section, vehicleMakes) {
    const makesSelectionValues = vehicleMakes || makes
    let $makeSelect = $section.find('.make');
    let $familySelect = $section.find('.family');

    populateVehicleSelect($makeSelect, makesSelectionValues, 'Any Make');

    $section
        .find('.make, .family')
        .off('change')
        .on('change', function () {
            let $select = $(this);
            let isSectionInitialising = $section.data('initOnOpen');

            if ($select.hasClass('make') && !isSectionInitialising) {
                $familySelect.val('');
                populateVehicleSelect($familySelect, [], 'Any Model');

                if (!$select.val()) {
                    $familySelect.attr('disabled', '');
                    return;
                }
            }

            getFamily($section, $select, (body) => {
                if ($select.hasClass('make')) {
                    populateVehicleSelect($familySelect, body.aggregations.family_bucket.buckets, 'Any Model');
                    if ($select.val()) {
                        $familySelect.removeAttr('disabled');
                    }
                }
            });
        });
};

const populateVehicleSelect = function ($select, buckets, placeholder, currentValue) {
    currentValue = currentValue || $select.val();
    $select.html('');
    $select.append('<option value="">' + placeholder + '</option>');

    let list = buckets.map((bucket) => {
        return bucket.key;
    });

    let matched = list.filter((item) => {
        return item === currentValue
    })[0];

    if (!matched && currentValue) {
        list.push(currentValue);
    }

    list.alphanumSort(true);

    list.forEach((item) => {
        $select.append('<option value="' + item + '">' + item + '</option>');
    });

    $select.val(currentValue);
};

const getFamily = function ($section, $changedSelect, callback) {
    let $makeSelect = $section.find('.make');

    let data = {
        body: {
            size: 0,
            query: {bool: {must: []}},
            aggs: {}
        }
    };

    if ($changedSelect.hasClass('make')) {
        data.body.query.bool.must.push({match: {make: $makeSelect.val()}});
        data.body.aggs.family_bucket = {terms: {field: "family", "size": 1000}};
    }

    $.ajax({
        type: 'POST',
        url: '/inventory/' + filterType + '/filter',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        ifModified: true,
        success: callback
    });
};

$vehicleTabs.on('click', function () {
    let $activeTab = $(this);
    let $makes = $('[dealershipMakes]')
    if ($makes.attr('dealershipMakes')) {
        const newMakes = JSON.parse($makes.attr('dealershipMakes'));

        const newMakesArray = _filter(makes, (m) => newMakes.includes(m.key));

        if ($activeTab.text().trim() === 'New' && newMakesArray.length > 0) {
            setupMakeSection($('.filter__section--make'), newMakesArray);
        } else {
            setupMakeSection($('.filter__section--make'));
        }
    }
});

if ($vehicleSearchTool && $vehicleSearchTool.length > 0) {
    populateMakeField();

    $vehicleSearchTool.on('submit', function (e) {
        e.preventDefault();
        const form = $(this);
        const $make = form.find('.make');
        const $family = form.find('.family');

        const makeValue = [$make.val(), $family.val()].join(',').trim();
        let params = form.serialize();
        params = params.split('&').map((param) => {
            if (param.indexOf('make') > -1) {
                return `makes=${makeValue}`;
            }
            if (param.indexOf('displayPrice') > -1) {
                return `displayPrice=,${param.split('=')[1]}`;
            }
            if (!(param.indexOf('family') > -1)) {
                return param;
            }
        }).filter(Boolean).join('&')
        window.location.href = `/inventory/cars?${params}`;
    });
}
