/* eslint-disable no-cond-assign */
Array.prototype.alphanumSort = function(caseInsensitive) {
    for (let z = 0, t; t = this[z]; z++) {
        this[z] = [];
        let x = 0, y = -1, n = 0, i, j;

        while (i = (j = t.charAt(x++)).charCodeAt(0)) {
            let m = (i === 46 || (i >=48 && i <= 57));
            if (m !== n) {
                this[z][++y] = "";
                n = m;
            }
            this[z][y] += j;
        }
    }

    this.sort(function(a, b) {
        for (let x = 0, aa, bb; (aa = a[x]) && (bb = b[x]); x++) {
            if (caseInsensitive) {
                aa = aa.toLowerCase();
                bb = bb.toLowerCase();
            }
            if (aa !== bb) {
                let c = Number(aa), d = Number(bb);
                if (c === aa && d === bb) {
                    return c - d;
                } else return (aa > bb) ? 1 : -1;
            }
        }
        return a.length - b.length;
    });

    for (let z = 0; z < this.length; z++)
        this[z] = this[z].join && this[z].join("") || this[z];
};