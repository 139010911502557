const formUtil = require('./form-util');
const utils = require('./utils');
const analytics = require('./analytics');

const changeFormState = function ($elem, toState) {
    $elem
        .removeClass('form--pending')
        .removeClass('form--success')
        .removeClass('form--failed');

    if (toState) {
        $elem.addClass(toState);
    }
};

// Selects all forms of type enquiry
$('[form-type^="enquiry"]').each((index, elem) => {
    let $formContainer = $(elem);
    let $form = $formContainer.find('form');
    let uuid = $form.attr('id');
    let fieldsets = JSON.parse($form.attr('data-fieldsets'));
    let $closeLink = $($formContainer).find('.form__success .link');
    let $tryAgainLink = $($formContainer).find('.form__failed .link');
    if (fieldsets) {
        fieldsets = fieldsets.reduce((result, fieldset) => {
            const fields = fieldset?.fields?.reduce((fieldResults, field) => {
                if (!field?.name?.includes('customer.')) fieldResults.push(field);
                return fieldResults
            }, [])
            if (fields?.length > 0) result.push({...fieldset, fields})
            return result;
        }, [])
    }

    formUtil.validateInputsOnChange($form);

    formUtil.catchSubmit($form, (invalid, body) => {
        if (!invalid) {
            let type = elem.getAttribute('form-type').replace('enquiry-', '');
            let analyticEvents = {
                category: $form.attr('analytics-event-category') ? $form.attr('analytics-event-category') : type,
                action: $form.attr('analytics-event-action') ? $form.attr('analytics-event-action') : 'enquiry',
                label: $form.find('select[name="ENQUIRYTYPE"]').val() ? $form.find('select[name="ENQUIRYTYPE"]').val() : $form.attr('analytics-event-label')
            };
            changeFormState($formContainer, 'form--pending');
            const files = $form.find(`[type='file']`);
            formUtil.upload(files, body).then((body) => {
                body.uuid = uuid;
                body.type = type;
                body.urlpath = window.location.pathname;
                body.userDefinedFieldsets = fieldsets
                if (body.userDefinedFieldsets?.[0]) body.userDefinedFieldsets[0].id = 'custom-fields';

                $.ajax({
                    type: 'POST',
                    url: '/Enquiry/' + type,
                    contentType: "application/json; charset=utf-8",
                    data: JSON.stringify({body: body}),
                    ifModified: true,
                    success: function () {
                        const enquiryType = $form.find('[name=ENQUIRYTYPE]').val() || '';
                        const enquiryFormType = $form.find('[name=FORMTYPE]').val() || '';

                        // Google analytics tracking
                        analytics.trackGoogleEvent(analyticEvents.category, analyticEvents.action, analyticEvents.label);

                        // Facebook tracking registration with the enquiry type as the value passed
                        analytics.trackFacebookRegistration();

                        // Clear the form data
                        formUtil.resetForm($form);
                        changeFormState($formContainer, 'form--success');

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'form.submission',
                            'succeeded': true,
                            'value': true,
                            'formType': type,
                            'enquiryType': enquiryType || enquiryFormType
                        });

                        utils.fireEvent('form-success', body);
                    },
                    error: function () {
                        const enquiryType = $form.find('[name=ENQUIRYTYPE]').val() || '';
                        const enquiryFormType = $form.find('[name=FORMTYPE]').val() || '';

                        // Google analytics tracking
                        analytics.trackGoogleEvent(analyticEvents.category, analyticEvents.action + '-fail', analyticEvents.label);

                        changeFormState($formContainer, 'form--failed');

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'form.submission',
                            'succeeded': false,
                            'value': false,
                            'formType': type,
                            'enquiryType': enquiryType || enquiryFormType
                        });
                    }
                });
            }).catch(err => {
                err.status === 413
                    ? $formContainer.find('.card__body.card__description').html(err.data) :
                    $formContainer.find('.card__body.card__description').html("An error occurred and your details could not be sent through.");
                changeFormState($formContainer, 'form--failed');
            })
        }
    });

    $tryAgainLink.on('click', () => {
        formUtil.focusFirst($form);
        changeFormState($formContainer);
    });

    $closeLink.on('click', () => {
        // Wait for modal to finish animating
        setTimeout(() => {
            formUtil.resetForm($form);
            changeFormState($formContainer);
        }, 500);
    });
});

$('select').on('change', function () {
    const $form = $(this).closest('form');
    const emailToInput = $form?.find('[name="emailTo"]');
    if (emailToInput?.length > 0) {
        const val = $(this).val();
        const option = $form.find(`[value='${val}']`);
        let optionEmailVal = option.data('emailto');
        if (optionEmailVal?.length > 0) {
            emailToInput.val(optionEmailVal);
        }
    }
});

$('input[type="checkbox"][data-emailto]').on('change', function () {
    const $form = $(this).closest('form');
    const emailToInput = $form?.find('[name="emailTo"]');
    const checkboxEmailVal = $(this).data('emailto');
    if (emailToInput?.length > 0) {
        $(this).prop('checked') ? emailToInput.val(checkboxEmailVal) : emailToInput.val("");
    }
});