//======================================================================================================================
// Binds functionality to filter the offers page by type and brand
//======================================================================================================================
const $empty = $('#empty__state_offers');
const $categorySelect = $('#offerCategorySelect');

let $offerGroups = $('.grid--cards');

// Define the offer card elements and construct an array of their
let offers = $('.card--offer')
    .map(function (index, offer) {
        let $offer = $(offer);
        let categories = $offer?.data('categories')?.split('|');
        let offerObj = {
            elem: offer,
            categories: {}
        };

        categories?.forEach(function (category) {
            if (category) {
                offerObj.categories[category] = true;
            } else {
                offerObj.categories['Special Offers'] = true;
            }
        });

        return offerObj;
    });

$categorySelect.on('change', () => {
    filterCardsByCategory();
});

/**
 * Loops through the offers and sets the class 'card--display' if the brand and type match
 */
function filterCardsByCategory() {
    let hasOffers = false;

    if ($categorySelect && !$categorySelect[0]) {
        return;
    }
    if (offers) {
        // Show and hide offers
        offers.forEach(function (offer) {
            let val = $categorySelect[0].value;
            let categoryPass = !val || offer.categories[val];
            if (categoryPass) {
                offer.elem.classList.add('card--display');
                hasOffers = true;
            } else {
                offer.elem.classList.remove('card--display');
            }
        });

        // Show and hide offer group elements
        $offerGroups.forEach(function (el) {
            el = $(el).parent();
            let cardsDisplayed = el.find('.card--display').length;
            if (cardsDisplayed) {
                el.show();
            } else {
                el.hide();
            }
        });
        // Show and hide empty state
    }
    if (hasOffers === true) {
        $empty.hide();
    } else {
        $empty.show();
    }
}


$("#reset__filters").on("click", function () {
    $categorySelect.val('');
    filterCardsByCategory();
});
filterCardsByCategory();
