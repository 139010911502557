const analytics = require('./analytics');
const filter = require('./inventory/filters')

const initFinanceCalculator = function () {
    const $form = $('.taurus-finance-calculator');

    if (!$form.length) {
        return;
    }

    const $inventoryContainer = $('#inventory_container');

    const $sliderMaxPrice = $('[name="SLIDER_MAX_PRICE"]');
    const $sliderMinPrice = $('[name="SLIDER_MIN_PRICE"]');

    const $maxPrice = $('[name="MAX_PRICE"]');
    const $minPrice = $('[name="MIN_PRICE"]');

    const $minCalculatorOutput = $('[min-calculator-estimate]');
    const $maxCalculatorOutput = $('[max-calculator-estimate]');

    const $loanTerm = $('[name="LOANTERM"]');
    const $interestRate = $('[name="LOANINTEREST"]').last();
    const $monthlyFees = $('[name="MONTHLYFEES"]');
    const $establishmentFees = $('[name="ESTABLISHMENTFEES"]');
    const $stepper = $('.filter__stepper-button');
    const $loanTermMin = parseInt($loanTerm.attr('min'));
    const $loanTermMax = parseInt($loanTerm.attr('max'));
    let analyticsSent = false;
    let url = new URL(window.location.href);
    let search_params = url.searchParams;
    let $displayPrice = search_params.get('displayPrice');

    const checkLoanTerm = () => {
        if ($loanTerm.val() < $loanTermMin) {
            $loanTerm.val($loanTermMin)
        }

        if ($loanTerm.val() > $loanTermMax) {
            $loanTerm.val($loanTermMax)
        }
    }

    const queryES = () => {
        if (!$inventoryContainer.length) {
            return;
        }

        let url = new URL(window.location.href);
        let search_params = url.searchParams;

        if (search_params.get('displayPrice')) {
            let maxValue = parseInt(search_params.get('displayPrice').split(',')[1]);
            if (maxValue !== $maxPrice.val()) {
                search_params.set('displayPrice', `${$minPrice.val()},${$maxPrice.val()}`);
                window.history.pushState({ path: decodeURIComponent(url) }, '', decodeURIComponent(url));
                filter.init(true);
            }
        } else {
            search_params.set('displayPrice', `${$minPrice.val()},${$maxPrice.val()}`);
            window.history.pushState({ path: decodeURIComponent(url) }, '', decodeURIComponent(url));
            filter.init(true);
        }
    }

    if ($displayPrice) {
        const values = $displayPrice.split(',');

        if ($sliderMinPrice.length) {
            $sliderMinPrice.val(values[0] || 0);
            $minPrice.val(values[0] || 0);
        }

        if ($sliderMaxPrice.length) {
            $sliderMaxPrice.val(values[1]);
            $maxPrice.val(values[1]);
        }

        queryES();
    }

    $stepper.forEach(function (el) {
        $(el).on('click', function () {
            getInputVal(this)
        });
    });

    $(".taurus-finance-calculator input").on('keyup change', function () {
        if ($(this).attr('name') === 'SLIDER_MIN_PRICE' && $minPrice.length > 0) {
            $minPrice.val($(this).val());
        }

        if ($(this).attr('name') === 'SLIDER_MAX_PRICE' && $maxPrice.length > 0) {
            $maxPrice.val($(this).val());
        }

        calculate();
    });

    $sliderMaxPrice.on('input', () => {
        $maxPrice.val($sliderMaxPrice.val());
        $maxPrice.trigger('change');
    })

    $sliderMinPrice.on('input', () => {
        $minPrice.val($sliderMinPrice.val());
        $minPrice.trigger('change');
    })

    $maxPrice.on('change', function () {
        $sliderMaxPrice.val($maxPrice.val());
        $sliderMaxPrice.trigger('change');
        calculate();
    });

    $minPrice.on('change', function () {
        $sliderMinPrice.val($minPrice.val());
        $sliderMinPrice.trigger('change');
    });

    $sliderMaxPrice.on('mouseup touchend', () => queryES())
    $maxPrice.on('blur', () => queryES())

    $sliderMinPrice.on('mouseup touchend', () => queryES())
    $minPrice.on('blur', () => queryES())

    $loanTerm.on('blur', () => {
        checkLoanTerm();
        calculate();
    })

    let getInputVal = function (input) {
        const direction = $(input).attr('data-direction');
        const $input = $(input).parent().find('input');
        const incrementBy = $input.attr('stepper');
        const fixed = $input.attr('fixed') || 2;

        if (direction === 'forward') {
            $input.val(Number(Number($input.val()) + Number(incrementBy)).toFixed(Number(fixed)));
        } else if (direction === 'backward') {
            $input.val(Number(Number($input.val()) - Number(incrementBy)).toFixed(Number(fixed)));
        }

        if ($input.attr('name') === 'LOANTERM') {
            checkLoanTerm()
        }

        if ($input.attr('name') === 'MIN_PRICE' && $sliderMinPrice.length > 0) {
            $sliderMinPrice.val($input.val());
            $sliderMinPrice.trigger('change');
            queryES();
        }

        if ($input.attr('name') === 'MAX_PRICE' && $sliderMaxPrice.length > 0) {
            $sliderMaxPrice.val($input.val());
            $sliderMaxPrice.trigger('change');
            queryES();
        }

        calculate();
    };

    let calculateMonthlyRepayment = () => {
        let price = Number($maxPrice.val());
        let financedFees = Number($establishmentFees.val());
        let rate = Number($interestRate.val());
        let term = Number($loanTerm.val()) * 12; //Turn into months
        let akf = Number($monthlyFees.val());

        return ((price + financedFees) * (rate / 1200)) / (1 - (1 + rate / 1200) ** (-term)) + akf;
    }

    let calculate = function () {
        let monthlyRepayment = calculateMonthlyRepayment();
        let output = 0;
        let $repaymentMethod = $form.find('[name="PAYMENTFREQUENCY"]:checked');

        if ($repaymentMethod.length) {
            output = monthlyRepayment * 12 / +$repaymentMethod.val();
        } else {
            output = +$maxPrice.val();
        }

        if (output && output !== Infinity) {
            $maxCalculatorOutput.html(Math.round(output));
        } else {
            $maxCalculatorOutput.html('–');
        }

        if ($minPrice.val()) {
            $minCalculatorOutput.html(Math.round(+$minPrice.val()));
        } else {
            $minCalculatorOutput.html('–');
        }

        if (!analyticsSent) {
            analytics.trackGoogleEvent("pre-approval", "Finance Calculator Used", window.location.href);
            analyticsSent = true;
        }
    }

    queryES();
    calculate();
}

initFinanceCalculator();

module.exports = {
    initFinanceCalculator
}