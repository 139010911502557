const utils = require('./utils');

function download(filename, text) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([text], {type: 'text/calendar;charset=utf-8'});
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const tmp = document.createElement('a');
        tmp.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(text));
        tmp.setAttribute('download', filename);
        tmp.style.display = 'none';
        document.body.appendChild(tmp);
        tmp.click();
        document.body.removeChild(tmp);
    }
}

$('[generate-ics]').click(function () {
    const $calendar = $(this);
    const filename = $calendar.data('filename') || 'event';

    let calendarData = {
        title: $calendar.data('title'),
        startType:"local",
        start: $calendar.data('start') ? $calendar.data('start').split('-') : [],
        end: $calendar.data('end') ? $calendar.data('end').split('-') : [],
        description: $calendar.data('description'),
        location: $calendar.data('location'),
        url: $calendar.data('url'),
        geo: {
            lat: parseFloat($calendar.data('geo-lat')),
            lon: parseFloat($calendar.data('geo-lon'))
        }
    };

    $.ajax({
        type: 'POST',
        url: `/calendar/${filename}`,
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(calendarData),
        ifModified: true,
        success: function (response) {
            download(`${filename}.ics`, response);
        },
        error: function () {
            utils.fireEvent('showerrortoast', {message: 'An unexpected error has occurred and your file could not be downloaded. Please try again later.'});
        }
    });
});