const $select = $('select[tab]');
const $tabs = $('.tab');
const $tabsSections = $('[tab-id]');
const $tabUnderline = $('.tabs--underline');
const $window = $(window);

$tabs.on('click', function () {
    let $activeTab = $(this);
    if (!$activeTab.hasClass('active')) {
        $tabs.forEach((tab) => {
            $(tab).removeClass('active');
        });

        $activeTab.addClass('active');
        $tabUnderline.css({ 'transform': 'translateX(' + $activeTab.position().left + 'px)' });
        $tabUnderline.css({ 'width': $activeTab.width() + 'px' });
        if ($activeTab.attr('data-id')) {
            $select.val($activeTab.attr('data-id').trim());
            showSection($activeTab.attr('data-id').trim());
        }
    }
});

$select.on('change', function () {
    let $options = $(this).find('option');
    let $selected = null;

    $options.each((index, el) => {
        let $option = $(el);
        if ($option.prop('selected')) {
            $selected = $option;
        }
    });

    $tabs.forEach((tab) => {
        let $tab = $(tab);
        $tab.removeClass('active');
        if ($(tab)?.attr('data-id')?.trim() === $selected.val()) {
            $tab.addClass('active');
            $tabUnderline.css({ 'transform': 'translateX(' + $tab.position().left + 'px)' });
            $tabUnderline.css({ 'width': $tab.width() + 'px' });
        }
    });

    showSection($selected.val());
});

$window.on('resize', function () {
    $tabs.forEach((tab) => {
        let $tab = $(tab);
        if ($tab.hasClass('active')) {
            $tabUnderline.css({ 'transform': 'translateX(' + $tab.position().left + 'px)' });
            $tabUnderline.css({ 'width': $tab.width() + 'px' });
        }
    });
});

const showSection = (id) => {
    if ($tabsSections && $tabsSections.length > 0) {
        $tabsSections.forEach((section) => {
            let $section = $(section);
            $section.removeClass('active');
            if ($section.attr('tab-id').trim() === id) {
                $section.addClass('active');
            }
        });
    }
}

$select.trigger('change');

if ($tabs && $tabs.length > 0) {
    $tabs[0].click();
}