let $tabs = $('[button-tab]');

function hideAll(exceptions) {
    $tabs.forEach(function (tab, index) {
        let $container = $('#' + tab.getAttribute('button-tab').replace('Btn', ''));

        if (index === exceptions && $container?.[0]?.style?.display) {
            $container[0].style.display = 'inherit';
            return;
        }
        if ($container?.[0]?.style?.display) $container[0].style.display = 'none';
    });
}

$tabs.forEach(function (tab, index) {
    let $container = $('#' + tab.getAttribute('button-tab').replace('Btn', ''));

    if (index === 0) {
        hideAll(index);
    }

    $(tab).on('click', function () {
        hideAll();
        if ($container?.[0]?.style?.display) $container[0].style.display = 'inherit';
    });
});
