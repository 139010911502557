/**
 *
 * @author Joey
 */

const _tableElementList = document.querySelectorAll('.table:not(.table--borderless--no-padding)');
const tableElementList = Array.prototype.slice.call(_tableElementList);

const setActiveColumn = function (table, index) {
    let _tableHeaders = table.querySelectorAll('.table__header > .table__cell');
    let tableHeaders = Array.prototype.slice.call(_tableHeaders);
    let _tableRows = table.querySelectorAll('.table__row');
    let tableRows = Array.prototype.slice.call(_tableRows);

    // Loop through the headers and set the appropriate one to active, whilst removing the active off
    // all other elements
    tableHeaders.forEach(function (elem, _index) {
        if (_index === index) {
            elem.classList.add('active');
        } else {
            elem.classList.remove('active');
        }
    });

    // Loop through all rows and obtain all cells within each
    tableRows.forEach(function (row) {
        let _cells = row.querySelectorAll('.table__cell');
        let cells = Array.prototype.slice.call(_cells);

        // Loop through each cell and set the appropriate one to active, whilst removing the active off
        // all other elements
        cells.forEach(function (elem, _index) {
            if (_index === index) {
                elem.classList.add('active');
            } else {
                elem.classList.remove('active');
            }
        });
    });
};
const headerPressedStartFn = function (table, headers) {
    // If the header is already active, then exit the event
    if (this.classList.contains('active')) {
        return
    }

    // Add the touch class
    this.classList.add('touch');

    // Hide all other cells except the ones that belong to the active column
    let pos = headers.indexOf(this);

    // Set the active column
    setActiveColumn(table, pos);
};
const headerPressedEndFn = function () {
    this.classList.remove('touch');
};

tableElementList.forEach(function (table) {
    // Get the list of headers
    let isFeatureTable = table.classList.contains('table--feature');
    let _headers = table.querySelectorAll('.table__header > .table__cell');
    let headers = Array.prototype.slice.call(_headers);

    headers.forEach(function (header, index) {
        // Initialise the table with the first column active
        if ((!isFeatureTable && index === 0) || (isFeatureTable && index === 1)) {
            setActiveColumn(table, index);
        }

        // Skip the adding of the events for the first element if it is a feature table
        if (isFeatureTable && index === 0) {
            return;
        }

        // Mouse events for desktop
        header.addEventListener('mousedown', headerPressedStartFn.bind(header, table, headers));
        header.addEventListener('mouseup', headerPressedEndFn);

        // Touch events for mobile
        header.addEventListener('touchstart', headerPressedStartFn.bind(header, table, headers));
        header.addEventListener('touchend', headerPressedEndFn);
    });
});
