const analytics = require('../analytics');
const photoSwipe = require('../photoswipe/photoswipe');
let $swiper;
const $vehicleCarousel = $('.carousel--vehicle');
$vehicleCarousel.each((index, elem) => {
    $swiper = $(elem);
    let vehicleSwiper = new window.Swiper(elem, {
        loop: false,
        grabCursor: true,
        a11y: true,
        canSwipe: true,
        autoHeight: true,
        lazyLoading: true,
        preloadImages: false,
        lazyLoadingInPrevNext: true,
        lazyLoadingInPrevNextAmount: 2,
        slidesPerView: 2,
        spaceBetween: 2,
        iOSEdgeSwipeDetection: true,
        keyboardControl: true,
        nextButton: '.carousel--vehicle .swiper-button-next',
        prevButton: '.carousel--vehicle .swiper-button-prev',
        breakpoints: {
            544: {
                slidesPerView: 1,
                slidesPerGroup: 1,
            }
        },
        onInit: function () {
            photoSwipe('.gallery--vehicle');
        },
        onSlideChangeEnd: function (swiper) {
            analytics.trackGoogleEvent($swiper.data('carouselName'), 'swipe carousel', 'slide ' + (swiper.activeIndex + 1));
        },
        onClick: function () {
            photoSwipe('.gallery--vehicle');
        }
    });
    window.addEventListener('updateVehicleSwiper', function (e) {
        vehicleSwiper.slideTo(e.detail, false);
        vehicleSwiper.lazy.load();

    }, false);
});