/* eslint-disable no-unused-vars */
let savedScrollTop = null;

const smallScreenWidth = 544;
const mediumScreenWidth = 812;
const largeScreenWidth = 992;
const xLargeScreenWidth = 1200;
const xxLargeScreenWidth = 2560;

const checkBreakpoint = width => window.innerWidth <= width;
const isMobile = () => !!window.navigator.userAgent.match(/Mobi/);

module.exports = {
    findObject: function (list, propertyName, value) {
        return list.find(function (item) {
            if (item && item.hasOwnProperty(propertyName)) {
                if (item[propertyName] === value) {
                    return true;
                }
            }
        });
    },
    deepEqual: function (a, b) {
        if ((typeof a === 'object' && a !== null) &&
            (typeof b === 'object' && b !== null)) {
            let count = [0, 0];
            for (let key in a) count[0]++;
            for (let key in b) count[1]++;
            if (count[0] - count[1] !== 0) {
                return false;
            }

            for (let key in a) {
                if (!(key in b) || !this.deepEqual(a[key], b[key])) {
                    return false;
                }
            }

            for (let key in b) {
                if (!(key in a) || !this.deepEqual(b[key], a[key])) {
                    return false;
                }
            }
            return true;
        } else {
            return a === b;
        }
    },
    disableBodyScrolling: function ($scrollableElement) {
        let _clientAxis = null;

        $scrollableElement.on('touchstart', function (event) {
            if (event.targetTouches.length === 1) {

                // detect single touch and handle orientation
                if (window.innerHeight > window.innerWidth) {
                    _clientAxis = event.targetTouches[0].clientY;
                } else {
                    _clientAxis = event.targetTouches[0].clientX;
                }
            }
        });

        $scrollableElement.on('touchmove', function (event) {
            if (event.targetTouches.length === 1) {

                // detect single touch
                disableRubberBand(event);
            }
        });

        function disableRubberBand(event) {

            let clientAxis;
            if (window.innerHeight > window.innerWidth) {
                clientAxis = event.targetTouches[0].clientY - _clientAxis;
            } else {
                clientAxis = event.targetTouches[0].clientX - _clientAxis;
            }

            if ($scrollableElement[0].scrollTop === 0 && clientAxis > 0) {

                // element is at the top of its scroll
                event.preventDefault();
            }

            if (isOverlayTotallyScrolled() && clientAxis < 0) {
                //element is at the bottom of its scroll
                event.preventDefault();
            }
        }

        function isOverlayTotallyScrolled() {
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
            return $scrollableElement[0].scrollHeight - $scrollableElement[0].scrollTop <= $scrollableElement[0].clientHeight;
        }
    },
    fireEvent: function (event, param) {
        let eventAction = new CustomEvent(event, { detail: param });
        window.dispatchEvent(eventAction);
    },
    enableBodyScrolling: function ($scrollableElement) {
        $scrollableElement.off('touchStart');
        $scrollableElement.off('touchmove');
    },
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    debounce: function (func, wait, immediate) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        }
    },
    // Returns a function, that, when invoked, will only be triggered at most once
    // during a given window of time. Normally, the throttled function will run
    // as much as it can, without ever going more than once per `wait` duration;
    // but if you'd like to disable the execution on the leading edge, pass
    // `{leading: false}`. To disable execution on the trailing edge, ditto.
    throttle: function (func, wait, options) {
        let context, args, result;
        let timeout = null;
        let previous = 0;
        if (!options) options = {};
        let later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function () {
            let now = Date.now();
            if (!previous && options.leading === false) previous = now;
            let remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    },
    toNumberFormatted: function (value) {
        let hitsFormatted = value.toLocaleString();
        if (hitsFormatted.indexOf(".") > -1) {
            hitsFormatted = hitsFormatted.substring(0, hitsFormatted.indexOf("."));
        }

        return hitsFormatted;
    },
    smallScreenWidth: smallScreenWidth,
    mediumScreenWidth: mediumScreenWidth,
    largeScreenWidth: largeScreenWidth,
    xLargeScreenWidth: xLargeScreenWidth,
    xxLargeScreenWidth: xxLargeScreenWidth,
    isSmallScreen: () => checkBreakpoint(smallScreenWidth),
    isMediumScreen: () => checkBreakpoint(mediumScreenWidth),
    isLargeScreen: () => checkBreakpoint(largeScreenWidth),
    isXLargeScreen: () => checkBreakpoint(xLargeScreenWidth),
    isXXLargeScreen: () => checkBreakpoint(xxLargeScreenWidth),
    isMobile,
    getElementAutoHeight: function (elem) {
        // Create a hidden node on the page where we can put the element
        let parentNode = elem.parentNode;
        let shadowNode = document.createElement('div');
        let elemClone = elem.cloneNode(true);

        // Apply styles to the shadowNode before adding it to the DOM
        shadowNode.style.position = 'absolute';
        shadowNode.style.opacity = 0;
        shadowNode.style.top = 0;
        shadowNode.style.width = elem.offsetWidth + 'px';
        shadowNode.style.zIndex = -1;

        parentNode.appendChild(shadowNode);
        shadowNode.appendChild(elemClone);

        // Remove any transitions
        elemClone.style.webkitTransition = '';
        elemClone.style.mozTransition = '';
        elemClone.style.transition = '';

        elemClone.style.height = 'auto';

        // Obtain the auto height
        let height = elemClone.offsetHeight;

        // Remove the shadowNode
        parentNode.removeChild(shadowNode);

        return height;
    },
    /**
     * Function handles the adding and removing of the active class on a given DOM element
     */
    toggleActive: function (elem) {
        let _elem = elem || this;
        if (_elem.classList.contains('active')) {
            _elem.classList.remove('active');
        } else {
            _elem.classList.add('active');
        }
    },
    lockBody: function (bool) {
        const html = document.querySelector('html');

        if (bool) {
            // Mimic a frozen frame by setting the content to fixed position

            // We get the scroll top off the window object as the scroll top value
            // only exists on the window level in firefox
            savedScrollTop = $(window).scrollTop();

            html.style.overflow = 'hidden';
            if (this.isMediumScreen()) {
                html.style.position = 'fixed';
                html.style.width = '100%';
                html.style.top = -savedScrollTop + 'px';
            }
        } else {
            // Reset the body
            html.style.overflow = '';
            html.style.position = '';
            html.style.width = '';
            html.style.top = '';
            $(window).scrollTop(savedScrollTop);
        }
    },
    urlParam: function (name) {
        if (name) {
            let results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results === null) {
                return null;
            } else {
                return decodeURI(results[1]) || 0;
            }
        }
    }
};