/**
 *
 * @author emilio
 *
 * Carousel data properties:
 * id (String REQUIRED)                 - ID to differentiate between multiple carousels on one page
 * slidesPerView (number, type based)   - Configurable if type != 'card' but will always be 1 for mobile view
 * type (card || null, null)            - Sets configuration specific to a type
 * fullScreen (boolean, false)          - Optional full screen. Not used if type === 'card'
 * arrowNavigation (boolean, false)     - Optional arrow navigation
 * pagination (boolean, false)          - Optional display of pagination
 * canSwipe (boolean, false)            - Optional add ability to swipe by mouse or touch
 * minHeight (number, 0)                - Minimum allowed height of the carousel
 * maxHeight (number, null)             - Maximum height of the carousel
 * title (string, '')                   - Title to overlay the center of the carousel
 * aspectRatio (number, null)           - Aspect ratio to maintain while vertical height is over minHeight
 * tint (boolean, false)                - Of the carousel should have a dark transparent blue overlay
 * autoPlay (number, null)              - If the carousel should auto swipe at the given time
 * spaceBetween (number, null)          - Adds a gap between slides
 * numberOfSlides (number, null)        - Total number of slides. Used if it is expected the total number of slides is expected to be less than the number of slides per view
 * pairedSwiperID (string)              - Specific to Gateway Section component where two carousels are paired
 */

const utils = require('./utils');
const smoothScroll = require('./smooth-scroll');
const events = {
    initialiseCarouselNav: new CustomEvent('initialise-carousel-nav'),
    initialiseSVGGradient: new CustomEvent('initialise-svg-gradient')
};

// Selects all carousels that aren't navigation for another Swiper instance
let carouselQuery = '.swiper-container:not(.carousel__nav)';
let _carousels = document.querySelectorAll(carouselQuery);
let carousels = Array.prototype.slice.call(_carousels);
constructSwipers(carousels);

function resizeSwiperSlides(carousel) {
    // noinspection JSSuspiciousNameCombination
    let height = carousel.clientWidth;
    let minHeight = 650;
    let maxHeight = minHeight;

    if (carousel.attributes.aspectRatio.value) {
        height = carousel.clientWidth / parseFloat(carousel.attributes.aspectRatio.value);
        maxHeight = height;

    } else {
        // If the screen size is larger xLargeScreenWidth then calculate the max height
        if (!utils.isXLargeScreen()) {
            // noinspection JSSuspiciousNameCombination
            maxHeight = utils.largeScreenWidth;
            height = ((carousel.clientWidth - utils.xLargeScreenWidth) / (utils.xxLargeScreenWidth - utils.xLargeScreenWidth)) * (utils.largeScreenWidth - minHeight) + minHeight;
        }
    }
    // Set iFrame height to be the carousel height
    let slides = carousel.querySelectorAll('.swiper-slide');
    for (let i = 0; i < slides.length; i++) {
        // Set the width and height of the slide
        slides[i].style.width = carousel.clientWidth + 'px';
        slides[i].style.height = height + 'px';
        slides[i].style.maxHeight = maxHeight + 'px';
        slides[i].style.opacity = '1';
    }
}

function constructSwipers(carousels) {
    let constructedSwipers = {};

    carousels.forEach(function (carousel) {

        // Ignore the vehicle carousel till this code is refactored
        if (carousel.classList.contains('carousel--vehicle')) {
            return;
        }

        // Define the swiper options using attributes on the carousel
        let swiperOptions = {
            roundLengths: true,
            loop: false,                    // Loop the carousel images
            grabCursor: true,               // Grab cursor if using desktop
            a11y: true,                     // enable keyboard accessibility to provide focusable navigation buttons and basic ARIA for screen readers
            keyboardControl: true,
            slidesPerGroup: 1
        };

        // Setup if mouse or touch device can be used to swipe
        let canSwipe = Boolean(carousel.attributes.canSwipe.value);
        swiperOptions.simulateTouch = canSwipe;
        swiperOptions.grabCursor = canSwipe;

        if (!canSwipe) {
            $(carousel).find('.swiper-wrapper').addClass('disabled');
        }

        // Models carousel configuration
        if (carousel.attributes.type.value === 'banner' || carousel.attributes.type.value === 'banner-legacy') {
            swiperOptions.lazyLoading = true;
            swiperOptions.preloadImages = false;
            swiperOptions.lazyLoadingInPrevNext = true;
            swiperOptions.lazyLoadingInPrevNextAmount = 0;
            swiperOptions.slidesPerView = 1;

            // Update the height and width of swiper-slides on resize
            swiperOptions.onAfterResize = function () {
                resizeSwiperSlides(carousel);
            };

            // Resize the slide before the carousel is initialised (required for IE)
            resizeSwiperSlides(carousel);
        } else if (carousel.attributes.type.value === 'variants') {
            swiperOptions.parallax = true;
            swiperOptions.canSwipe = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.slidesPerView = 'auto';

            if (carousel.attributes.onChangeScrollTo && carousel.attributes.onChangeScrollTo.value) {
                swiperOptions.onSlideChangeStart = function () {
                    smoothScroll.smoothScroll($(carousel.attributes.onChangeScrollTo.value), 50);
                };
            }

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1.2,
                    noSwiping: false,
                    centeredSlides: true,
                    spaceBetween: 16
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 1.1,
                    centeredSlides: true,
                    noSwiping: false,
                    spaceBetween: 16
                },
                [+utils.largeScreenWidth]: {
                    slidesPerView: 1.2,
                    centeredSlides: true,
                    noSwiping: false,
                    spaceBetween: 16
                }
            };

        } else if (carousel.attributes.type.value === 'dual-panel') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.autoplay = 0;
            swiperOptions.slidesPerView = 2;
            swiperOptions.slidesPerGroup = 1;
            swiperOptions.centeredSlides = false;

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1.1,
                    noSwiping: false,
                    centeredSlides: true,
                    spaceBetween: 16
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 1.5,
                    centeredSlides: true,
                    noSwiping: false
                }
            };
            carousel.style.opacity = 1;

        } else if (carousel.attributes.type.value === 'tri-panel') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.autoplay = 0;
            swiperOptions.slidesPerView = 3;
            swiperOptions.slidesPerGroup = 1;
            swiperOptions.centeredSlides = false;

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1.1,
                    noSwiping: false,
                    centeredSlides: true,
                    spaceBetween: 16
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 1.5,
                    centeredSlides: true,
                    noSwiping: false
                }
            };
            carousel.style.opacity = 1;

        } else if (carousel.attributes.type.value === 'inventory-cards') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.autoplay = 0;
            swiperOptions.slidesPerView = 4;
            swiperOptions.slidesPerGroup = 1;
            swiperOptions.observer = true;
            swiperOptions.centeredSlides = false;
            swiperOptions.observeParents = true;

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1.1,
                    noSwiping: false,
                    centeredSlides: true,
                    spaceBetween: 24,
                    observer: true,
                    observeParents: true,
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 1.5,
                    centeredSlides: true,
                    noSwiping: false,
                    observer: true,
                    observeParents: true

                },
                [+utils.largeScreenWidth]: {
                    slidesPerView: 2.5,
                    centeredSlides: false,
                    noSwiping: false,
                    observer: true,
                    observeParents: true
                }
            };
            carousel.style.opacity = 1;

        } else if (carousel.attributes.type.value === 'gallery') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 0;
            swiperOptions.autoplay = 0;
            swiperOptions.slidesPerView = 2;
            swiperOptions.centeredSlides = true;

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1,
                    noSwiping: false,
                    centeredSlides: true,
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 1,
                    centeredSlides: false,
                    noSwiping: false
                },
                [+utils.largeScreenWidth]: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    noSwiping: false
                }
            };
            carousel.style.opacity = 1;

        } else if (carousel.attributes.type.value === 'cards') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.autoplay = 0;
            swiperOptions.slidesPerView = 2.6;
            swiperOptions.slidesPerGroup = 1;
            swiperOptions.centeredSlides = true;

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1.1,
                    noSwiping: false,
                    centeredSlides: true,
                    spaceBetween: 16
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 1.5,
                    centeredSlides: false,
                    noSwiping: false
                },
                [+utils.largeScreenWidth]: {
                    slidesPerView: 2.5,
                    centeredSlides: false,
                    noSwiping: false
                }
            };
            carousel.style.opacity = 1;

        } else if (carousel.attributes.type.value === 'cards--small') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.autoplay = 0;
            swiperOptions.slidesPerView = 3.6;
            swiperOptions.slidesPerGroup = 1;
            swiperOptions.centeredSlides = true;
            swiperOptions.centerInsufficientSlides = true;

            swiperOptions.breakpoints = {
                [+utils.smallScreenWidth]: {
                    slidesPerView: 1.1,
                    noSwiping: false,
                    centeredSlides: true,
                    spaceBetween: 16
                },
                [+utils.mediumScreenWidth]: {
                    slidesPerView: 2.1,
                    centeredSlides: false,
                    noSwiping: false
                },
                [+utils.largeScreenWidth]: {
                    slidesPerView: 3.1,
                    centeredSlides: false,
                    noSwiping: false
                }
            };
            carousel.style.opacity = 1;

        } else if (carousel.attributes.type.value === 'full-cards') {
            swiperOptions.noSwiping = true;
            swiperOptions.spaceBetween = 32;
            swiperOptions.slidesPerView = 1;
            swiperOptions.slidesPerGroup = 1;
            swiperOptions.centeredSlides = true;

            carousel.style.opacity = 1;

        } else { // Default carousel settings - Slides width can be set through css properties and media queries
            let slidesPerView = carousel.attributes.slidesPerView.value;
            slidesPerView = typeof slidesPerView === "number" ? parseInt(slidesPerView) : slidesPerView.toString();

            swiperOptions.slidesPerView = slidesPerView || 1;
        }

        if (carousel.attributes.spaceBetween.value) {
            swiperOptions.spaceBetween = parseInt(carousel.attributes.spaceBetween.value);
        }

        // Setup autoPlay. Value is the viewing duration of a slide before it transitions to the next
        if (carousel.attributes.autoPlay.value) {
            let autoPlayValue = parseInt(carousel.attributes.autoPlay.value);
            swiperOptions.autoplay = autoPlayValue;
            swiperOptions.autoplayDisableOnInteraction = Boolean(autoPlayValue);
        }

        // Setup transition speed
        if (carousel.attributes.speed.value) {
            swiperOptions.speed = parseInt(carousel.attributes.speed.value);
        }

        // Setup arrow navigation
        if (carousel.attributes.arrowNavigation) {
            swiperOptions.prevButton = '.' + carousel.id + '-prev';
            swiperOptions.nextButton = '.' + carousel.id + '-next';
        }

        // Setup looping
        if (carousel.attributes.loop) {
            swiperOptions.loop = carousel.attributes.loop.value === 'true' || false;
        }

        // Setup pagination
        if (carousel.attributes.pagination) {
            swiperOptions.pagination = '.' + carousel.id + '-pagination';
            swiperOptions.paginationClickable = true;

            // Checks to see if the pagination is needed (amount of slides vs slides per view).
            let slidesPerView = carousel.querySelectorAll('.swiper-slide').length;
            let checkSlidesPerView = slidesPerView / swiperOptions.slidesPerView;
            let check = true;
            for (let key in swiperOptions.breakpoints) {
                if (swiperOptions.breakpoints.hasOwnProperty(key)) {
                    if ((parseInt(key) >= window.innerWidth) && check) {
                        checkSlidesPerView = slidesPerView / swiperOptions.breakpoints[key].slidesPerView;
                        check = false;
                    }
                }
            }
            if (checkSlidesPerView > 1) {
                swiperOptions.paginationBulletRender = function (swiper, index, className) {
                    // set the width of the bullets to be a percentage of the number of slides (10px comes from )
                    return '<span style="width: calc(' + 100 / slidesPerView + '% - 6px)" class="' + className + '"></span>';
                }
            } else {
                swiperOptions.pagination = false;
            }
        }

        // Setup centered slides
        if (carousel.attributes.centeredSlides) {
            swiperOptions.centeredSlides = carousel.attributes.centeredSlides.value === 'true';
        }

        // Setup lazy loading
        if (carousel.attributes.lazyLoading && Boolean(carousel.attributes.lazyLoading.value)) {
            swiperOptions.lazyLoading = true;
            swiperOptions.lazyLoadingInPrevNext = true;
            swiperOptions.lazyLoadingInPrevNextAmount = 1;
        }

        // Initially set the height and width of the swiper-slides
        swiperOptions.onInit = function () {
            // Display the carousel overlay only  when the carousel has initialised
            let $carouselContainer = $(carousel).closest('.carousel');
            let $carouselOverlay = $carouselContainer.find('.carousel__overlay');
            $carouselOverlay.css('display', 'block');
        };

        // Initialise the carousel
        constructedSwipers[carousel.id] = {
            // Was giving a Swiper is not defined error
            // eslint-disable-next-line
            swiper: new Swiper('#' + carousel.id, swiperOptions)
        };

        // Setup custom event handlers for paired carousels
        if (carousel.attributes.pairedSwiperID && carousel.attributes.pairedSwiperID.value) {
            let $thisSwiper = constructedSwipers[carousel.id].swiper;
            // Add ID of paired swiper as an attribute of the current swiper
            $thisSwiper.pairedSwiperID = carousel.attributes.pairedSwiperID.value;

            $thisSwiper.on("click", (e) => {
                let getIndex = (e.clickedIndex === 0 || e.clickedIndex) ? e.clickedIndex : e.activeIndex;
                let $pairedSwiper = $("#" + e.pairedSwiperID)[0].swiper;
                $thisSwiper.slideTo(getIndex);
                $pairedSwiper.slideTo(getIndex);
            });

            $thisSwiper.on("slideChangeEnd", (e) => {
                let $pairedSwiper = $("#" + e.pairedSwiperID)[0].swiper;
                $pairedSwiper.slideTo($thisSwiper.activeIndex);
            });
        }

        // Initialises the swiper navigation carousel after the primary carousel has been initialised
        if (swiperOptions.navigationEl) {
            let navOptions = {};
            navOptions.slidesPerView = 'auto';
            navOptions.spaceBetween = 32;
            navOptions.centeredSlides = true;
            navOptions.noSwiping = true;
            navOptions.freeMode = true;
            navOptions.freeModeSticky = true;
            navOptions.freeModeMomentumVelocityRatio = 1;
            navOptions.freeModeMomentumRatio = 0.5;
            navOptions.resistanceRatio = swiperOptions.resistanceRatio * 0.75;

            navOptions.breakpoints = {
                [+utils.mediumScreenWidth]: {
                    freeModeSticky: false,
                    noSwiping: false,
                    spaceBetween: 24
                }
            };

            // Was giving a Swiper is not defined error
            // eslint-disable-next-line
            let navigationSwiper = new Swiper(swiperOptions.navigationEl, navOptions);

            // Sets the carousel nav as the controller and vice versa
            constructedSwipers[carousel.id].swiper.params.control = navigationSwiper;

            swiperOptions.onAfterResize();
        }

        // TODO: Add photo gallery for full screen image swiping
    });

    // Broadcast an event to initialise carousel navs
    document.dispatchEvent(events.initialiseCarouselNav);
    document.dispatchEvent(events.initialiseSVGGradient);
    return constructedSwipers;
}

$(document)
    .off('initialise-carousels')
    .on('initialise-carousels', () => {
        _carousels = document.querySelectorAll(carouselQuery);
        carousels = Array.prototype.slice.call(_carousels);

        constructSwipers(carousels);
    });

window.addEventListener('switchSlide', function (e) {
    const carousel = $('#' + e.detail.id)[0].swiper;
    carousel.slideTo(e.detail.slideNo);
}, false);


module.exports = {
    constructSwipers
}