const $videoPlayers = $('.video-player');
const relativeBucketUrl = $('#relativeBucketUrl').val();
const utils = require('./utils');
const analytics = require('./analytics');

const notifyAndTrackError = (label) => {
    utils.fireEvent('showerrortoast', { message: 'An unexpected error has occurred and the video could not be loaded. Please try again later.' });
    analytics.trackGoogleEvent('Video Player', 'Playback Error', label);
};

$videoPlayers.each((i, el) => {
    const $container = $(el);
    const $thumbnail = $container.find('.video-player__thumbnail');
    const $playButton = $container.find('.video-player__play-button');
    const $video = $container.find('video');
    const sources = $video.data('sources');

    $playButton.click(() => {
        let path = '';

        // Find the appropriate video path
        if (sources && sources.length > 1) {
            if (utils.isMediumScreen()) {
                const mediumSrc = sources.find(src => src.resolution === '720');
                path = mediumSrc ? mediumSrc.path : '';
            } else {
                const largeSrc = sources.find(src => src.resolution === '1080');
                path = largeSrc ? largeSrc.path : '';
            }
        } else if (sources && sources.length === 1) {
            path = sources[0].path;
        }

        // If no video is found show an error message
        if (!path) {
            notifyAndTrackError('No video path was specified');
        } else {
            const src = relativeBucketUrl + path;

            try {
                $video.attr('src', src);
                $video[0].play();
                analytics.trackGoogleEvent('Video Player', 'Playback', 'Video played at path: ' + src);
                $video.css('opacity', 1);
                $container.addClass('dirty');
                $playButton.hide();
                $thumbnail.hide();
            } catch (e) {
                notifyAndTrackError('An unexpected error occurred when attempting to play video at path: ' + src);
            }
        }
    });
});