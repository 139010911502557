const $inventoryContainer = $('#inventory_container');
const $desktopFiltersContainer = $('.inventory__filters');
const animEndEvents = 'animationend webkitAnimationEnd';
const utils = require('../utils');
const filterAccordionTemplate = require('!!raw-loader!./filter-accordion.html').default;
const filterHeadingDesktop = require('!!raw-loader!./filter-heading-desktop.html').default;
let $desktopFilters = $desktopFiltersContainer.find('.filter__overlay');
let previousHash = null;
let currentFilterLayout = null;

let registerHashBangChanges = function () {

    $(window)
        .on('hashchange', function () {
            let state = (previousHash || '');
            let $elem;
            if (!state) {
                $elem = $('#filters');
            } else {
                $elem = $('#' + state);
            }

            $elem.find('.filter__form-button').trigger('click');
        });
};

let deregisterHashBangChanges = function (clearHashBang) {
    $(window).off('hashchange');

    if (clearHashBang) {
        window.history.pushState({}, '', window.location.pathname + window.location.search);
        previousHash = null;
    }
};

// User Interactions (Mobile)
let initialiseFilterNavigation = function () {
    let $filters = $('#filters');
    let $openFiltersDesktop = $('#open_filters_desktop');
    let $openFilters = $('#open_filters');
    let $menuItems = $filters.find('.filter__menu-item');

    // Open the filter (Mobile)
    $openFilters
        .off('click')
        .on('click', function () {
            window.history.pushState({}, '', window.location.pathname + window.location.search + '#filters');
            previousHash = 'filters';

            $desktopFiltersContainer.show();
            $filters.show();

            // Disable scrolling on the body
            $('.filters--modal-with-button')
                .each((index, item) => {
                    utils.disableBodyScrolling($(item));
                });

            setTimeout(function () {

                // Slide in the filters element
                $filters
                    .addClass('enter')
                    .on(animEndEvents, function () {
                        $filters.off(animEndEvents);

                        // Prevent scrolling on the main content
                        if (!utils.isMobile()) {
                            $('html, body').addClass('overflow--hidden');
                        }

                        // Disable slide animation styles
                        $filters.addClass('no-slide').removeClass('enter');

                        // Watch for when the hash changes
                        registerHashBangChanges()
                    });
            }, 0);
        });

    $menuItems
        .off('click')
        .on('click', function () {

            let menuId = $(this).data('menuId');
            let $menu = $('#' + menuId);

            deregisterHashBangChanges();
            window.history.replaceState({}, '', window.location.pathname + window.location.search + '#' + menuId);
            previousHash = menuId;


            // Show menu this will be off screen at this point
            $menu.show();

            // Slide the current filter menu out of view
            $filters.removeClass('slide-in-bottom no-slide');
            $filters.addClass('slide-out-left enter');

            $filters
                .on(animEndEvents, function () {
                    $filters.off(animEndEvents);

                    $filters.hide();

                    $filters.removeClass('slide-out-left enter');
                    $filters.addClass('slide-out-left no-slide');
                });

            // Slide in the new filter menu
            setTimeout(function () {
                $menu
                    .addClass('enter')
                    .on(animEndEvents, function () {
                        $menu.off(animEndEvents);

                        // Disable slide animation styles
                        $menu.addClass('no-slide').removeClass('enter');

                        // Watch for when the hash changes
                        registerHashBangChanges()
                    });
            }, 0);

            // 'Done' button
            $menu
                .find('.filter__form-button')
                .off('click')
                .on('click', function () {
                    $filters.removeClass('slide-out-left no-slide');
                    $filters.show();

                    deregisterHashBangChanges();
                    window.history.replaceState({}, '', window.location.pathname + window.location.search + '#filters');
                    previousHash = 'filters';

                    setTimeout(function () {
                        $filters.addClass('slide-in-left enter');
                        $filters
                            .on(animEndEvents, function () {
                                $filters.off(animEndEvents);

                                $filters.removeClass('slide-in-left enter');
                                $filters.addClass('slide-in-bottom no-slide');
                            });
                    }, 0);

                    // Handle when the animation finishes
                    $menu
                        .removeClass('slide-in-right no-slide')
                        .addClass('slide-out-right enter')
                        .on(animEndEvents, function () {
                            $menu.off(animEndEvents);


                            $menu.removeClass('slide-out-right enter');
                            $menu.addClass('slide-in-right');

                            // Reset scroll top position
                            $menu.find('.filters')[0].scrollTop = 0;

                            $menu.hide();

                            // Watch for when the hash changes
                            registerHashBangChanges()
                        });
                });
        });

    let filterStateCleanUp = function () {
        // Enable scrolling on the body
        $('.filters--modal-with-button')
            .each((index, item) => {
                utils.enableBodyScrolling($(item));
            });

        if (!utils.isMobile()) {
            $('html, body').removeClass('overflow--hidden');
        }
    };

    // Filter menu 'Show Inventory' button
    $filters
        .find('.filter__form-button')
        .off('click')
        .on('click', function () {
            deregisterHashBangChanges(true);

            filterStateCleanUp();

            $filters.removeClass('slide-in-bottom no-slide');
            $filters.addClass('slide-out-bottom enter');

            // Handle when the animation finishes
            $filters
                .on(animEndEvents, function () {
                    $filters.off(animEndEvents);

                    $filters.removeClass('slide-out-bottom enter');
                    $filters.addClass('slide-in-bottom');

                    // Reset scroll top position
                    $filters.find('.filters')[0].scrollTop = 0;

                    $filters.hide();
                    $desktopFiltersContainer.hide();
                });
        });

    filterStateCleanUp();

    $openFiltersDesktop
        .off('click')
        .on('click', function () {
            $openFiltersDesktop.data('open', true);
            $inventoryContainer.addClass('filters--open');
            $desktopFiltersContainer.show();

            let $firstAccordion = $inventoryContainer.find('.accordion').first();
            let $content = $firstAccordion.find('.accordion__content');
            if (!$content[0].hasAttribute('style')) {
                $firstAccordion.find('.accordion__switch').trigger('click');
            }

            $('#close_filters')
                .off('click')
                .on('click', function () {
                    $desktopFiltersContainer.hide();
                    $inventoryContainer.removeClass('filters--open');
                    $openFiltersDesktop.data('open', false);
                });
        });
};

// User Interactions (Desktop)
let transformFilterLayoutTo = function (type) {
    if (currentFilterLayout === type) {
        return;
    }

    switch (type) {
        case 'desktop': {
            $desktopFiltersContainer.hide();

            $desktopFilters.removeClass('animate no-slide');
            $desktopFilters.find('.filter__form-button').hide();
            $desktopFilters.find('.filter__section--inverted').hide();
            $desktopFilters.show();

            // Wrap all filter menus with an accordion template
            $desktopFilters.each((index, filter) => {
                let $filter = $(filter);
                let $accordion = $(filterAccordionTemplate);
                let accordionId = $filter.attr('id') + '_accordion';
                let accordionHeading = $filter.find('.filter__heading').text();

                if (accordionHeading.toLowerCase() === 'filters') {
                    accordionHeading = 'Basics';
                }

                $accordion.find('input').attr('id', accordionId);
                $accordion.find('label').attr('for', accordionId);
                $accordion.find('.title').text(accordionHeading);
                $accordion.find('.accordion__content').append($filter[0].outerHTML);

                $filter.replaceWith($accordion);

                $('<hr/>').insertAfter($('#' + accordionId).parent());
            });

            $(document.body).trigger('reload_accordion');

            $(filterHeadingDesktop).insertBefore($desktopFiltersContainer.children().first());

            let $openFiltersDesktop = $('#open_filters_desktop');
            $openFiltersDesktop.data('open', true);
            $inventoryContainer.addClass('filters--open');
            $desktopFiltersContainer.show();

            let $firstAccordion = $inventoryContainer.find('.accordion').first();
            let $content = $firstAccordion.find('.accordion__content');
            if ($content[0]) {
                if (!$content[0].hasAttribute('style')) {
                    $firstAccordion.find('.accordion__switch').trigger('click');
                }
            }

            $('#close_filters')
                .off('click')
                .on('click', function () {
                    $desktopFiltersContainer.hide();
                    $inventoryContainer.removeClass('filters--open');
                    $openFiltersDesktop.data('open', false);
                });

            break;
        }
        case 'mobile':
            $desktopFiltersContainer.hide();
            $inventoryContainer.removeClass('filters--open');

            // Unwrap filter sections that are accordions
            $inventoryContainer
                .find('.accordion')
                .each((index, accordion) => {
                    let $accordion = $(accordion);
                    $accordion.replaceWith($accordion.find('.filter__overlay'));
                });

            $inventoryContainer.find('.inventory__filters hr').remove();
            $('#close_filters').remove();

            $desktopFilters = $desktopFiltersContainer.find('.filter__overlay');

            $desktopFilters.hide();
            $desktopFilters.find('.filter__section--inverted').show();
            $desktopFilters.find('.filter__form-button').show();
            $desktopFilters.addClass('animate');

            break;
    }

    currentFilterLayout = type;

    setTimeout(function () {
        initialiseFilterNavigation();
        initialiseFilterButtons();
    }, 25);
};

let refreshFilterLayout = function (initialSetup) {
    if (utils.isMediumScreen()) {
        transformFilterLayoutTo('mobile', initialSetup);
    } else {
        transformFilterLayoutTo('desktop', initialSetup);
    }
};

refreshFilterLayout(true);

// General Active states

let initialiseFilterButtons = function () {
    $('.filter__button')
        .off('click')
        .on('click', function () {
            let $elem = $(this);
            if ($elem.hasClass('filter__button--active')) {
                $elem.removeClass('filter__button--active')
            } else {
                $elem.addClass('filter__button--active')
            }
        });

    $('.filter__list').find('.filter__list-item')
        .off('click')
        .on('click', function () {
            let $elem = $(this);
            if ($elem.hasClass('filter__list-item--active')) {
                $elem.removeClass('filter__list-item--active')
            } else {
                $elem.addClass('filter__list-item--active')
            }
        });

    $('#ancap_filter')
        .find('.icon-star')
        .off('click')
        .on('click', function () {
            let $this = $(this);
            $this.parent()
                .find('.icon-star')
                .each((index, star) => {
                    let $star = $(star);
                    if ((index + 1) <= $this.data('rating')) {
                        $star.addClass('icon-star--active');
                    } else {
                        $star.removeClass('icon-star--active');
                    }
                });

        });

    $(document.body).trigger('filters_rebuilt')
};

module.exports = {
    initialiseFilterButtons: initialiseFilterButtons
};


$(window)
    .on('resize orientationchange', function () {
        window.requestAnimationFrame(() => {
            refreshFilterLayout();
        });
    });