const $items = $('[data-start-date] , [data-expiry-date]');


$items.each((i, el) => {
    const $item = $(el);
    const startDate = $item.data('start-date') ? moment($item.data('start-date')).valueOf() : undefined;
    const expiryDate = $item.data('expiry-date') ? moment($item.data('expiry-date')).valueOf() : undefined;
    const $days = $item.find('[days]');
    const $hours = $item.find('[hours]');
    const $minutes = $item.find('[minutes]');
    const $seconds = $item.find('[seconds]');

    const $labelDays = $item.find('[label-days]');
    const $labelHours = $item.find('[label-hours]');
    const $labelMinutes = $item.find('[label-minutes]');

    let counter = setInterval(updateDate, 1000);

    function updateDate() {
        let now = moment().valueOf();
        let distance = expiryDate - now;

        if (startDate && (startDate > now)) {
            removeRibbon();
        } else if (distance > 0) {
            $item.css("opacity", 1);
            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            $days.text(days);
            $hours.text(hours);
            $minutes.text(minutes);
            $seconds.text(seconds);

            if (days === 0) {
                $days.hide();
                $labelDays.hide();
                if (hours === 0) {
                    $hours.hide();
                    $labelHours.hide();
                    if (minutes === 0) {
                        $minutes.hide();
                        $labelMinutes.hide();
                    }
                }
            }
        } else if (expiryDate) {
            removeRibbon()
        } else {
            $item.css("opacity", 1);
        }
    }

    function removeRibbon() {
        $item.remove();
        clearInterval(counter);
    }

    updateDate();
});