const calendars = {}
const $timeSlots = $('.time-slots');
let timeStops;
let today = moment(new Date()).format("YYYY-MM-DD");
let $calendar = $('.calendar');
let WORK_HOURS_MAPPER = [];

function RolyartCalendar(config, calendar, id) {
    this.id = id;
    this.container = calendar;
    this.container.classList.add('rolyart-calendar');
    this.today = new Date();
    this.selected = this.today;
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.months = config.months;
    this.weekDays = config.weekDays;

    /**
     * Calendar navigation
     * nextMonth()
     * prevMonth()
     */

    this.nextMonth = () => {
        if (this.currentMonth === 11) {
            this.currentMonth = 0;
            this.currentYear = this.currentYear + 1;
        } else {
            this.currentMonth = this.currentMonth + 1;
        }
        this.showCalendar(this.currentYear, this.currentYear);
    }
    this.prevMonth = () => {
        if (this.currentMonth === 0) {
            this.currentMonth = 11;
            this.currentYear = this.currentYear - 1;
        } else {
            this.currentMonth = this.currentMonth - 1;
        }
        this.showCalendar(this.currentYear, this.currentYear);
    }

    /**
     * Get days of month
     * getPrevDays()
     * getNextDays()
     * getCurrentDays()
     */
    this.getPrevDays = (date, staDay = 0) => {
        let ret = [];
        let year = date.getFullYear();
        let month = date.getMonth();
        let firstWeekday = new Date(year, month, 1).getDay();
        let days = (firstWeekday + 7) - (staDay + 7) - 1;
        for (let i = days * -1; i <= 0; i++) {
            ret.push({ date: new Date(year, month, i).getDate(), id: new Date(year, month, i) });
        }
        return ret;
    }
    this.getNextDays = () => {
        let date = new Date(this.currentYear, this.currentMonth + 1, 1);
        let ret = [];
        let year = date.getFullYear();
        let month = date.getMonth();
        let firstWeekday = date.getDay();
        let days = 7 - firstWeekday;
        if (days < 7) {
            for (let i = 1; i <= days; i++) {
                ret.push({ date: i, id: moment(new Date(year, month, i)).format("YYYY-MM-DD") });
            }
        }
        return ret;
    }
    this.getCurrentDays = (date) => {
        let ret = [];
        let year = date.getFullYear();
        let month = date.getMonth();
        let lastDay = new Date(year, month + 1, 0).getDate();
        for (let i = 1; i <= lastDay; i++) {
            ret.push({ date: i, id: moment(new Date(year, month, i)).format("YYYY-MM-DD") });
        }
        return ret;
    }

    this.calendarHeader = () => {
        let header = document.createElement('header');
        header.setAttribute('id', this.id);
        header.classList.add('calendar-header');
        header.classList.add('calendar-header');
        header.classList.add('u-justify-content--space-between');
        let monthAndYear = document.createElement('label');
        let calendarControl = document.createElement('div');
        let prevMonth = document.createElement('button');
        let currentMonth = document.createElement('button');
        let nextMonth = document.createElement('button');

        monthAndYear.classList.add('month-year');
        calendarControl.classList.add('calendar-control');
        monthAndYear.innerHTML = `${this.months[this.currentMonth] + ' ' + this.currentYear}`;

        prevMonth.innerHTML = '←'
        prevMonth.addEventListener('click', () => {
            this.prevMonth();
            monthAndYear.innerHTML = `${this.months[this.currentMonth] + ' ' + this.currentYear}`;
        })

        nextMonth.innerHTML = '→'
        nextMonth.addEventListener('click', () => {
            this.nextMonth();
            monthAndYear.innerHTML = `${this.months[this.currentMonth] + ' ' + this.currentYear}`;
        })


        currentMonth.innerHTML = '<i class="current-month"></i>'
        currentMonth.addEventListener('click', () => {
            this.currentYear = new Date().getFullYear();
            this.currentMonth = new Date().getMonth();
            monthAndYear.innerHTML = `${this.months[this.currentMonth] + ' ' + this.currentYear}`;
            this.showCalendar();
        })

        let weekDays = document.createElement('div');
        weekDays.classList.add('week-days');
        for (let i = 0; i <= 6; i++) {
            weekDays.innerHTML += `<div>${this.weekDays[i]}</div>`;
        }

        currentMonth.classList.add('control-current-month');

        calendarControl.appendChild(prevMonth)
        calendarControl.appendChild(currentMonth)
        calendarControl.appendChild(nextMonth);
        header.appendChild(monthAndYear)
        header.appendChild(calendarControl);

        this.container.appendChild(header);
        this.container.appendChild(weekDays);
    }

    this.calendarBody = (year, month) => {
        const $form = $(this.container).closest('form');
        const $dateInput = $form.find('[type=date]')
        let $this = $(this.container);
        year = this.currentYear;
        month = this.currentMonth;
        let date = new Date(year, month + 1, 0);
        let daysPrevMonth = this.getPrevDays(date);
        let daysThisMonth = this.getCurrentDays(date);
        let daysNextMonth = this.getNextDays(daysPrevMonth, daysThisMonth);

        let calendarBody = document.createElement('div');
        calendarBody.classList.add('calendar-body');
        [...daysPrevMonth, ...daysThisMonth, ...daysNextMonth]
            .forEach(num => {
                let selected = $this.find(".selected");
                let cell = document.createElement('div');
                let date = moment(num.id).format("YYYY-MM-DD");

                if ($dateInput.val() === num.id) {
                    cell.classList.add('selected');
                } else {
                    cell.classList.remove('selected')
                }

                cell.setAttribute('id', num.id);
                cell.classList.add('day');
                let day = document.createElement('span');

                day.innerHTML = num.date;
                cell.appendChild(day);

                if (moment(date).isBefore(today, 'day') || !num.id) {
                    cell.classList.add('disabled');
                }

                if (!$dateInput.val() && num.id === moment(this.today).format("YYYY-MM-DD")) {
                    cell.classList.add('selected');
                    setTimes(date, $form);
                    $dateInput.val(num.id).trigger('change');
                }

                cell.addEventListener('click', () => {
                    this.selected = num.id;
                    let date = moment(num.id).format("YYYY-MM-DD");
                    selected = $this.find(".selected");
                    selected.forEach((item) => {
                        item.classList.remove('selected')
                    })
                    if (selected.length > 0) {
                        selected[0].className = selected[0].className.replace(" selected", "");
                    }

                    cell.className += " selected";
                    setTimes(date, $form);

                    $dateInput.val(num.id).trigger('change');
                });

                calendarBody.appendChild(cell);
            })
        this.container.appendChild(calendarBody);
    }

    this.showCalendar = (year, month) => {
        this.container.innerHTML = '';
        this.calendarHeader();
        this.calendarBody(year, month);
    }

    this.showCalendar(this.currentYear, this.currentMonth);

    this.reset = () => {
        let $this = $(this.container);
        $this.find(".selected").forEach((item) => {
            item.classList.remove('selected')
        });
        setTimeout(() => {
            this.showCalendar();
        }, 100)
    }
}

const setTimes = (date, $form) => {
    const $timeInput = $form.find('[type=time]')
    $timeInput.val('').trigger('change');
    let startTime, endTime;
    if (WORK_HOURS_MAPPER.length > 0) {
        let dayNum = new Date(date).getDay();
        startTime = WORK_HOURS_MAPPER[dayNum].open
        endTime = WORK_HOURS_MAPPER[dayNum].closed
        if (date === today) {
            let currentHour = moment().format("HH");
            currentHour = parseInt(currentHour) + 1;
            if (currentHour < parseInt(endTime)) {
                startTime = currentHour;
            } else if (currentHour >= parseInt(endTime)) {
                startTime = null;
                endTime = null;
            }
        }
    }
    timeStops = getTimeStops(startTime, endTime);
    $timeSlots.empty();
    const $dealershipPicker = $form.find('input[name="dealershipId"]');
    if ($dealershipPicker && $dealershipPicker.length > 0 && !$dealershipPicker.val()) {
        $timeSlots.append(`<div>Please select your preferred dealership to begin.</div>`);
    } else if (timeStops.length <= 0) {
        $timeSlots.append(`<div>There are no available times for this date. Please select another date.</div>`);
    } else {
        timeStops.forEach((time) => {
            $timeSlots.append(`<div class="time-slot" value="${time}">${moment(time, "hh").format('LT').toLowerCase()}</div>`);
        });
    }

    $('.time-slot').click(function () {
        let $active = $(this)
        $('.time-slot').forEach((elm) => {
            $(elm).removeClass('active');
        })
        setTimeout(() => {
            $active.addClass('active');
        })
        $timeInput.val($active.attr('value')).trigger('change');
    })
}

function getTimeStops(start, end) {
    let startTime = moment(start, 'HH:mm');
    let endTime = moment(end, 'HH:mm');

    if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
    }

    let timeStops = [];

    while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format('HH:mm'));
        startTime.add(1, 'hours');
    }
    return timeStops;
}

let calendarConfig = {
    container: 'calendar',
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    weekDays: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
}

if ($calendar && $calendar.length > 0) {
    $calendar.forEach((calendar, i) => {
        calendars[i] = new RolyartCalendar(calendarConfig, calendar, `${i}`);
    })
}

$('[dealership-select]').on('change', function () {
    const $form = $(this).closest('form');
    const calendar = $form.find('.calendar');
    if (calendar && calendar.length > 0) {
        const $timeInput = $form.find('[type=time]')
        const $dateInput = $form.find('[type=date]')

        //Resets the calendar
        calendars[calendar.find('header').attr('id')].reset();

        // Delay to allow the DOM to update
        setTimeout(() => {
            const $departmentWorkHours = $form.find('[department-open-hours]');
            WORK_HOURS_MAPPER = $departmentWorkHours.data('department-timetable');
            // Put sunday at the start of the week mapper
            WORK_HOURS_MAPPER ? WORK_HOURS_MAPPER.unshift(WORK_HOURS_MAPPER.pop()) : [];

            // Remove whats currently stored
            $dateInput.val('').trigger('change');
            $timeInput.val('').trigger('change');
        }, 1)
    }
});

window.addEventListener('closeModal', function (e) {
    let $modal = e.detail
    const $form = $modal.find('form');
    if ($form && $form.length > 0) {
        const calendar = $form.find('.calendar');
        if (calendar && calendar.length > 0) {
            WORK_HOURS_MAPPER = [];
            //Resets the calendar
            calendars[calendar.find('header').attr('id')].reset();
        }
    }
}, false);