// Polyfills
require('./polyfills');

// Vendor Scripts
require('hammer-timejs/dist/hammer-time.min.js');
window.moment = require('moment');
window.$ = require('zeptojs');
require('custom-event-polyfill');
window.Swiper = require('swiper/dist/js/swiper');
require('swiper/dist/css/swiper.css');

require('photoswipe/dist/default-skin/default-skin.css');
require('photoswipe/dist/photoswipe.css');
window.PhotoSwipe = require('photoswipe/dist/photoswipe');
window.PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');

// Below are the list of global assets that are required for each site
// Javascript
const req = require.context('./js', true, /\.js$/);
req.keys().forEach(req);