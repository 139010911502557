let winScrollTop = 0;
let win = $(window);
let parallaxAmount = 0.066;
if (window.innerWidth < 812) {
    parallaxAmount = 0.06;
}

$.fn.is_on_screen = function () {
    let viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
    };
    //viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    let bounds = $(this).offset();
    //bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + $(this).height();

    return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};

function parallax() {
    // let scrolled = $(window).scrollTop();
    $('.parallax-section').each(function () {
        if ($(this).is_on_screen()) {
            window.requestAnimationFrame(() => {
                let firstTop = $(this).offset().top;
                let $img = $(this).find("img");
                let moveTop = Math.round((firstTop - winScrollTop) * parallaxAmount); //speed;
                $img.css("transform", "translateY(" + -moveTop + "px) scale(1.55)");
            });
        }

    });
}

$(window).scroll(function () {
    winScrollTop = $(this).scrollTop();
    parallax();
});