/**
 * Created by Joey
 */

// CSS Transition End

/**
 * Transition end management function which works out which process to invoke based on the
 * property that has finished transitioning
 */
function transitionEnd(event) {
    let _elem = event.target;

    if (_elem.classList && _elem.classList.contains('local-transition')) {
        return;
    }

    switch (event.propertyName) {
        case 'height':
            if (_elem.clientHeight === 0) {
                _elem.style.display = '';
                _elem.style.height = '';
            } else {
                _elem.style.height = 'auto';
            }
            break;
    }
}


window.addEventListener('transitionend', transitionEnd);
