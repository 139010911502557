const utils = require('./utils');
const observer = require('./lozad');

const $input = $('#dealership_postcode');
const $inputModal = $('#home_address');
const $select = $('#dealership_brand_select');
const currentLocationAction = $("#dealership_current_location");
const currentLocationSpinner = $("#dealership_current_location_spinner");

let body = {};
let dealershipsList = [];
let $dealershipSelectOptions = $('.content').find('[dealership-select][options]');

const filterDealerships = function (body) {
    $.ajax({
        type: 'POST',
        url: '/dealerships/filter',
        contentType: 'application/json',
        data: JSON.stringify({
            body: body
        }),
        ifModified: true,
        success: function (dealershipCards) {
            $('#dealership_cards').replaceWith(dealershipCards);
            observer.observe();
        }
    });
};


if ($input[0] || $inputModal[0]) {
    let watcher = setInterval(function () {

        if (typeof google !== 'undefined') {
            window.clearInterval(watcher);
            let autocomplete = new google.maps.places.Autocomplete($input[0] || $inputModal[0]);
            autocomplete.setOptions(
                {
                    types: [],
                    componentRestrictions: {country: 'au'}
                }
            );

            autocomplete.addListener('place_changed', function () {
                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    delete body.lat;
                    delete body.lon;
                } else {
                    body.lat = place.geometry.location.lat();
                    body.lon = place.geometry.location.lng();
                }

                // Filter the dealerships if a coordinate was given ELSE reset the input
                if (body.lat && body.lon) {
                    filterDealerships(body);
                } else {
                    if ($input[0]) {
                        $input.val(null);

                    }
                }
                let componentForm = {
                    street_number: 'short_name',
                    route: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    country: 'long_name',
                    postal_code: 'short_name'
                };
                let street_number = null;
                let route = null;

                if (place.address_components) {
                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            let val = place.address_components[i][componentForm[addressType]];
                            if (document.getElementById(addressType)) {
                                document.getElementById(addressType).value = val;
                            }
                            if (addressType === "street_number") {
                                street_number = val;
                            }
                            if (addressType === "route") {
                                route = val;
                            }
                        }
                    }
                    if (street_number && route) {
                        $("[name='USERADDRESS']")[0].value = street_number + ' ' + route;
                    } else if (route) {
                        $("[name='USERADDRESS']")[0].value = route;
                    }
                }
            });
        }
    }, 100);
}

$select
    .on('change', function () {
        body.brand = $(this).val();
        filterDealerships(body);
    });


if (currentLocationAction[0]) {
    currentLocationAction
        .on('click', function () {
            if (navigator && navigator.geolocation && !currentLocationAction.hasClass('disabled')) {

                // Reset and disable the suburb input
                if ($input[0]) {
                    $input.val('');
                    $input[0].setAttribute('disabled', '');
                }

                // Display loading state
                currentLocationAction.addClass('disabled');
                currentLocationSpinner.show();

                navigator.geolocation.getCurrentPosition(geolocationSuccess, geolocationError, {timeout: 10000});
            }
        });
}

function geolocationSuccess(position) {

    // Re enable the suburb input
    $input[0].removeAttribute('disabled');

    // Resolve loading state
    currentLocationAction.removeClass('disabled');
    currentLocationSpinner.hide();

    // If coordinates were returned, filter the dealerships
    if (position && position.coords) {
        body.lat = position.coords.latitude;
        body.lon = position.coords.longitude;
        filterDealerships(body);
    }
}

function geolocationError() {
    // Re enable the suburb input
    $input[0].removeAttribute('disabled');

    // Resolve loading state
    currentLocationAction.removeClass('disabled');
    currentLocationSpinner.hide();
}

/**
 * Adds options to a select for all dealerships with value as 'id' and display as 'siteName'
 *
 * @param $selects - Selects to add dealership options
 * @param options - Options
 */
function setDealershipSelect($selects, options) {
    if ($selects.length === 0 || $selects.attr('dealership-select-ignore-initialisation')) {
        return;
    }

    if ($selects.attr('options') && $selects.attr('options').length > 0) {
        if (options) {
            options.filters = !options.filters ? JSON.parse($selects.attr('options')) : options.filters
        } else {
            options = {filters: JSON.parse($selects.attr('options'))}
        }
    }

    if ($selects.attr('listAll') && $selects.attr('listAll').length > 0) {
        options.listAll = $selects.attr('options');
    }

    const $vehicleTypeButtons = $($selects.closest('[form-type]').find('form .filter__radio [name=VEHICLETYPE]'));
    // Wire up the vehicle type change event so that we can further filter the list of departments
    $vehicleTypeButtons.off('change').on('change', () => setDealershipSelect($selects, options));

    if ($vehicleTypeButtons.length) {
        const vehicleType = $vehicleTypeButtons.parent().find(':checked').val();
        if (vehicleType) {
            const channelFilter = {
                field: "channels",
                terms: vehicleType === "Car" ? ["CARS", "CAR_GATEWAYS"] : ["TRUCKS"]
            };

            if (options && options.filters) {
                if (!Array.isArray(options.filters)) {
                    options.filters = [options.filters, channelFilter];
                } else {
                    const index = options.filters.findIndex(filter => filter.field === 'channels');
                    if (index > -1) {
                        options.filters[index] = channelFilter;
                    } else {
                        options.filters.push(channelFilter);
                    }
                }
            } else if (options) {
                options.filters = [channelFilter];
            } else {
                options = {filters: [channelFilter]}
            }
        }
    }

    $.ajax({
        type: 'POST',
        url: '/dealerships/information',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(options),
        ifModified: true,
        success: function (response) {
            dealershipsList = response;
            $selects.each(function (index, select) {
                let $select = $(select);

                // Clear the select options
                $select.empty();

                // Add a blank select
                $select.append(createOption('', '', true, true));

                // Get the dealership title on the DDP page.
                let dealerTitle = $('[dealership-title]').text();

                // If the dealershipId is already set on the form then we want to set the matching option as selected
                let $form = $(this).closest('form');
                let dealerId = $form.find('input[name=dealershipId]').val();

                // Default select if there is only 1
                if (response.length === 1) {
                    dealerTitle = response[0].siteName;
                }

                // Add an option for all dealerships returned
                response.forEach(function (dealership) {
                    if ((dealerTitle && dealership.siteName === dealerTitle) || (dealerId && dealership.uuid === dealerId)) {
                        let option = createOption(dealership.uuid, dealership.siteName, false, true);
                        $select.append(option);
                        $select.trigger("change");
                    } else {
                        let option = createOption(dealership.uuid, dealership.siteName);
                        $select.append(option);
                    }
                });

                if (options && options.select) {
                    setTimeout(function () {
                        $form.find('select').val(options.select);
                    });
                }
            })
        }
    });
}

/**
 * Create an option element for a select.
 *
 * @param id - id of the option
 * @param display - display of the option
 * @param disabled - if the option should be disabled
 * @param selected - if the option should initially be selected
 * @return
 */
function createOption(id, display, disabled, selected) {
    let $option = $(document.createElement('option'));

    $option.val(id);
    $option.text(display || 'Select');

    if (disabled) {
        $option.attr('disabled', 'disabled');
    }

    // Set the option selected state
    if (selected) {
        $option.attr('selected', '');
    }

    return $option;
}

// Dealership selected triggers an update on the address and phone number
$('[dealership-select]').on('change', function (e) {
        const $form = $(this).closest('form');

        // Hidden Fields
        const $hiddenId = $form.find('input[name=dealershipId]');
        const $hiddenName = $form.find('input[name=DEALERSHIPNAME]');
        const $hiddenAddress = $form.find('input[name=DEALERSHIPADDRESS]');
        const $hiddenPhone = $form.find('input[name=DEALERSHIPPHONE]');
        const $hiddenLocationCode = $form.find('input[name=locationCode]');
        const $hiddenServiceAddress = $form.find('input[name=DEALERSHIPSERVICEADDRESS]');
        const $hiddenServicePhone = $form.find('input[name=DEALERSHIPSERVICEPHONE]');

        // Fields
        const formType = $form.find('[name="FORMTYPE"]').val() || '';
        const $dealershipAddress = $form.find('[dealership-address]');
        const $dealershipAddressSales = $form.find('[dealership-address-sales]');
        const $dealershipPhone = $form.find('[dealership-phone]');
        const $dealershipWebsite = $form.find('[dealership-website]');
        const $dealershipLicense = $form.find('[dealership-license]');
        const $dealershipLogo = $form.find('[dealership-logo]');

        const dealership = utils.findObject(dealershipsList, 'uuid', e.target.value);
        const serviceDepartment = dealership.departments
            ? dealership.departments.find(department => department.departmentName === 'Service') : null;
        const partsDepartment = dealership.departments
            ? dealership.departments.find(department => department.departmentName === 'Parts') : null;
        const salesDepartment = dealership.departments
            ? dealership.departments.find(department => department.departmentName === 'Sales') : null;
        const $dealershipWorkHours = $form.find('[department-open-hours]');
        let phoneNumber;
        let departmentWorkHours = null;

        // Construct the address using the DB address fields
        if (dealership) {
            let address;

            if (formType && formType.toLowerCase().indexOf('service') > -1) {
                for (let i = 0; i < dealership.departments.length; i++) {
                    if (dealership.departments[i].departmentName === 'Service') {
                        if (dealership.departments[i].phoneNumber) {
                            phoneNumber = dealership.departments[i].phoneNumber;
                        }
                    }

                }
                address = serviceDepartment.humanAddress;
                departmentWorkHours = serviceDepartment ? serviceDepartment.departmentWorkHours : null;
            }
            if (formType && formType.toLowerCase().indexOf('parts') > -1) {
                for (let i = 0; i < dealership.departments.length; i++) {
                    if (dealership.departments[i].departmentName === 'Parts') {
                        if (dealership.departments[i].phoneNumber) {
                            phoneNumber = dealership.departments[i].phoneNumber;
                        }
                    }
                }
                address = partsDepartment.humanAddress;
                departmentWorkHours = partsDepartment ? partsDepartment.departmentWorkHours : null;
            }

            if (formType && (formType.toLowerCase().indexOf('service') <= -1) && (formType.toLowerCase().indexOf('parts') <= -1)) {
                for (let i = 0; i < dealership.departments.length; i++) {
                    if (dealership.departments[i].departmentName === 'Sales') {
                        if (dealership.departments[i].phoneNumber) {
                            phoneNumber = dealership.departments[i].phoneNumber;
                        }
                    }
                }
                address = salesDepartment ? salesDepartment.humanAddress : null;
                departmentWorkHours = salesDepartment ? salesDepartment.departmentWorkHours : null;
            }

            address = address ? address || dealership.humanAddress : dealership.humanAddress;
            if (!phoneNumber) {
                phoneNumber = dealership.phoneNumber;
            }

            // Set hidden fields for service fields
            if ($hiddenServiceAddress) $hiddenServiceAddress.val(serviceDepartment?.humanAddress || '')
            if ($hiddenServicePhone) $hiddenServicePhone.val(serviceDepartment?.phoneNumber || '');

            $form.attr('analytics-event-label', dealership.siteName);
            $hiddenId.val(dealership.uuid);
            $hiddenName.val(dealership.siteName);
            $hiddenLocationCode.val(dealership.locationCode);
            $hiddenAddress.val(address || '');
            $hiddenPhone.val(phoneNumber || '');
            $dealershipAddress.html(address || '—');
            $dealershipAddressSales.html(dealership.humanAddress || '—');
            $dealershipPhone.html(phoneNumber || '—');
            $dealershipPhone.attr('href', phoneNumber ? 'tel:' + phoneNumber.replace(/[^0-9]/g, '') : '');
            $dealershipWebsite.html(dealership.primaryDomain || '—');

            if (dealership.emailLogo) {
                $dealershipLogo.html('<img alt="Dealership Logo" src="' + dealership.emailLogo + '"/>');
            } else {
                $dealershipLogo.html('<span>—</span>');
            }

            if (dealership.licenseNumber) {
                let numberSplit = dealership.licenseNumber.split(', ');
                let result = '';
                if (numberSplit[0]) {
                    result += 'MD' + numberSplit[0];
                }

                if (numberSplit[1]) {
                    result += ' MVRL' + numberSplit[1];
                }

                if (numberSplit[2]) {
                    result += ' MVRLM' + numberSplit[2];
                }

                $dealershipLicense.html(result);
            } else {
                $dealershipLicense.html('—');
            }

            if (departmentWorkHours) {
                $dealershipWorkHours.empty();

                $dealershipWorkHours.data('department-timetable', JSON.stringify(departmentWorkHours.timetable.map(day => {
                    if (day.status === 'open') {
                        return {
                            open: day.open,
                            closed: day.closed
                        }
                    } else {
                        return {
                            open: '',
                            closed: ''
                        }
                    }
                })));

                $.each(departmentWorkHours.summary, (i, interval) => {
                    if (interval && interval.fromTime && interval.toTime) {
                        if (i !== 0) {
                            $dealershipWorkHours.append(`</br>`);
                        }

                        $dealershipWorkHours.append(`<b>${interval.weekDay}:</b> ${interval.fromTime}–${interval.toTime}`);

                        if (interval.disclaimer) {
                            $dealershipWorkHours.append(`*<div class='fineprint--grey'>${interval.disclaimer}</div>`);
                        }
                    } else if (interval.disclaimer) {
                        $dealershipWorkHours.append(`<div class='fineprint--grey'>${interval.disclaimer}</div>`);
                    }
                });
            } else {
                $dealershipWorkHours.data('department-timetable', []);
                $dealershipWorkHours.html('No Information Available');
            }
        }
    }
);

// For service forms that aren't in modals
let $serviceForms = $('.content [form-type="enquiry-service"]').find('[dealership-select]');
if ($serviceForms.attr('options') && !$serviceForms.attr('options').length > 0) {
    setDealershipSelect($serviceForms, {
        filters: {
            field: 'departments.departmentName',
            terms: 'Service'
        }
    });
}

// For parts forms that aren't in modals
let $partsForms = $('.content [form-type="enquiry-parts"]').find('[dealership-select]');
if ($partsForms.attr('options') && !$partsForms.attr('options').length > 0) {
    setDealershipSelect($partsForms, {
        filters: {
            field: 'departments.departmentName',
            terms: 'Parts'
        }
    });
}

// For all forms that require a dealership-select with specific options
if ($dealershipSelectOptions && $dealershipSelectOptions.length > 0) {
    setDealershipSelect($dealershipSelectOptions);
}

module.exports = {
    setDealershipSelect
};