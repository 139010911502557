/*eslint-disable */
const utils = require('./utils');

let map, marker, bound, lastOpenInfoWindow, initialCoordinates, initialZoom;

function extractCoordinates(value) {
    if (!value) {
        return null;
    }
    const result = value.split(',').map(val => +val);
    return { lat: result[0] || 0, lng: result[1] || 0 };
}

function initGoogleMaps() {
    if (typeof google === 'undefined') {
        return
    }

    let dealershipMap = document.querySelectorAll('#dealership-map');
    let dealershipPano = document.querySelectorAll('#dealership-pano');

    // Set up Google Maps for each dealership location
    if (dealershipMap.length && dealershipMap.length > 0) {
        dealershipMap.forEach(function (elem) {
            let $elem = $(elem);
            let centerMap = null;
            let rawCoordinates = elem.attributes['data-coordinates'].value;
            let mapCoordinates = extractCoordinates(rawCoordinates);
            let offsetAmount = { lat: 0, lng: 0 };
            let zoom = 15;

            if (elem.attributes['offsetCenter']) {
                offsetAmount = extractCoordinates(elem.attributes['offsetCenter'].value)
            }

            if (mapCoordinates) {
                centerMap = mapCoordinates;
            }

            if (offsetAmount && centerMap) {
                centerMap.lat = offsetAmount.lat + centerMap.lat;
                centerMap.lng = offsetAmount.lng + centerMap.lng;
            }

            // Set default coordinates (Sydney) if none are supplied
            if (!centerMap) {
                centerMap = { lat: -33.8478796, lng: 150.7918939 };
            }

            if (elem.attributes['zoom']) {
                zoom = +elem.attributes['zoom'].value;
                if (window.innerWidth <= utils.mediumScreenWidth) {
                    zoom = zoom - 1;
                }
            }

            // Map options
            let mapOptions = {
                center: centerMap,
                disableDoubleClickZoom: false,
                fullscreenControl: false,
                gestureHandling: 'cooperative',
                mapTypeControl: false,
                rotateControl: false,
                scaleControl: false,
                scrollwheel: false,
                clickableIcons: false,
                draggable: true,
                streetViewControl: false,
                zoom,
                offset: offsetAmount
            };

            // Initialise dealership map
            map = new google.maps.Map(elem, mapOptions);

            bound = new google.maps.LatLngBounds();

            // Add supplied map markers
            if ($elem.attr('map-coordinates')?.length > 0 || $('[map-coordinates]')?.length > 0) {

                if ($elem.attr('map-coordinates')?.length > 0) {

                    addMarker(($elem.attr('map-coordinates')), $elem.attr('marker-title'), $elem.attr('marker-address'), 'multi');

                } else {

                    $('[map-coordinates]')
                        .each(function (index, element) {
                            const $marker = $(element);
                            addMarker($marker.attr('map-coordinates'), $marker.attr('marker-title'), $marker.attr('marker-address'), 'single', (index + 1) + (+!!mapCoordinates));
                        });

                }

            // Add default map markers
            } else {
                addMarker(rawCoordinates, null, null, null);
            }
        });
    }

    // Set up the dealership walkthrough
    if (dealershipPano && dealershipPano.length > 0) {
        dealershipPano.forEach(function (element) {
            let panoCoordinates = extractCoordinates(element.attributes['data-coordinates'].value);

            // Panorama options
            let panoOptions = {
                position: panoCoordinates,
                pov: {
                    heading: 34,
                    pitch: 10
                },
                addressControl: false,
                scrollwheel: false,
                motionTracking: false
            };

            // Initialise panorama
            let panorama = new google.maps.StreetViewPanorama(element, panoOptions);
        })
    }
}

let addMarker = function (mapCoordinates, title, address, mapType, amountOfMarkers) {
    // Add multiple markers to map
    mapCoordinates = extractCoordinates(mapCoordinates);
    if (map && mapCoordinates) {

        // Add info window if title and address are supplied
        if (title && address) {
            // Build the Google Maps URL to get directions
            let googleMapsDirectionsUrl =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                title.replace(/\s/g, "+") +
                ",+" +
                address.replace(/\s/g, "+");

            // Set the contents of the info window
            let contentString =
                '<div class="infoWindow">' +
                '<div class="infoWindow-title">' + title + '</div>' +
                '<div class="infoWindow-body">' +
                '<span>' + address.replace(", ", "<br>") + '</span>' +
                '<a href="' + googleMapsDirectionsUrl + '" target="_blank">Get Directions</a>' +
                "</div>" +
                "</div>";

            // Create and attach the info window to the marker
            let infoWindow = new google.maps.InfoWindow({
                content: contentString
            });

            // Adds marker
            marker = new google.maps.Marker({
                position: mapCoordinates,
                map,
                animation: google.maps.Animation.DROP,
                title
            });

            // Add info window event handler
            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    // Close the last open info window
                    if (mapType === 'single' && lastOpenInfoWindow) lastOpenInfoWindow.close();

                    // Sets default content for invalid address data
                    if (!address || address.length <= 10) infoWindow.setContent('<div class="infoWindow"><div class="infoWindow-title">' + title + '</div></div>');

                    // Opens this info window
                    infoWindow.open(map, marker);

                    // Set this as the last open info window
                    lastOpenInfoWindow = infoWindow;
                }
            })(marker));

            if (mapType === 'single') {
                readjustBounds(mapCoordinates);
            }

        } else {
            // Adds marker
            marker = new google.maps.Marker({
                position: mapCoordinates,
                map,
                animation: google.maps.Animation.DROP
            });
        }
    }
};

let changeMarkerPos = function (lat, lng) {
    if (map) {
        let offsetLat = Number(map.offset.lat) + Number(lat);
        let offsetLng = Number(map.offset.lng) + Number(lng);

        let offsetLatLng = new google.maps.LatLng(offsetLat, offsetLng);

        if (lat && lng) {
            let latLng = new google.maps.LatLng(lat, lng);
            marker.setPosition(latLng);
            map.panTo(offsetLatLng);
        }
    }
};

let readjustBounds = function (mapCoordinates) {
    bound.extend(new google.maps.LatLng(mapCoordinates.lat, mapCoordinates.lng));

    let newCenter = bound.getCenter();
    let offsetLat = Number(map.offset.lat) + Number(newCenter.lat());
    let offsetLng = Number(map.offset.lng) + Number(newCenter.lng());
    let offsetLatLng = new google.maps.LatLng(offsetLat, offsetLng);

    if (map.offset.lat === 0 && map.offset.lng === 0) {
        const currentZoom = map.getZoom();
        map.fitBounds(bound);
        const listener = google.maps.event.addListener(map, "idle", function() {
            if (map.getZoom() > currentZoom) map.setZoom(currentZoom);
            google.maps.event.removeListener(listener);
        });
    } else {
        map.panTo(offsetLatLng);
    }
};

let watcher = setInterval(function () {
    if (typeof google !== 'undefined') {
        window.clearInterval(watcher);
        initGoogleMaps();

        // Store the initial coordinates and zoom levels once the map loads fully
        let listener = google.maps.event.addListener(map, "idle", function() {
            initialCoordinates = map.getCenter();
            initialZoom = map.getZoom();
            google.maps.event.removeListener(listener);
        });
    }
}, 100);

module.exports = {
    initGoogleMaps: initGoogleMaps,
    changeMarkerPos: changeMarkerPos,
    addMarker: addMarker
};
/*eslint-enable */

