const sliders = $('.slider');

Number.prototype.map = function (in_min, in_max, out_min, out_max) {
    return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

sliders.each((i, el) => {
    const $el = $(el);
    const $input = $el.find('.slider__input');
    const $drag = $el.find('.slider__drag');
    const $bar = $el.find('.slider__bar');
    const $pricing = $('.pricing__cost');

    $input.on('input change', (e) => {
        window.requestAnimationFrame(() => {
            let price = Number(e.target.value);
            let value = ((price - $input.attr('min')) / ($input.attr('max') - $input.attr('min'))) * 100;
            $drag.css({ left: value + '%' });
            $bar.css({ 'background-position-x': (100 - value) + '%' });
            $pricing.html('$' + price);
        });
    });
    $input.trigger('change')
});