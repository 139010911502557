const utils = require('./utils');

// Desktop
const nav = document.querySelector('.nav');
const desktopNav = document.querySelector('.nav--desktop');
const _desktopNavItemList = document.querySelectorAll('.nav--desktop .nav__item');
const desktopNavItemList = Array.prototype.slice.call(_desktopNavItemList);
const navTint = document.querySelector('.nav__tint');
const navContact = document.querySelector('.nav__contact');
const navLogos = document.querySelector('.nav__logos');
const navDealership = document.querySelector('.nav__dealership');

// Mobile
const scrollUpToHide = document.querySelectorAll('[scrollUpToHide]');
const scrollDownToHide = document.querySelectorAll('[scrollDownToHide]');
const mobileNav = document.querySelector('.nav--mobile');
const mobileNavScrollTo = document.querySelectorAll('.nav--mobile [scrollto]');
const mobileNavButton = document.querySelector('.nav__button .button');
const mobileMenu = document.querySelector('.nav--mobile .nav__items');
const mobileMenuToggle = document.querySelector('.nav--mobile .nav__toggle');
const _mobileNavItemList = document.querySelectorAll('.nav--mobile .nav__item');
const mobileNavItemList = Array.prototype.slice.call(_mobileNavItemList);
const screenWidth = 812;
let handler;

// Determine when the
let navBreak = 0;

if (navContact) {
    navBreak += navContact.offsetHeight;
}

if (navLogos) {
    navBreak += 16;
}

/**
 *
 */
function navItemBindClick() {
    // Nav item bindings
    // Desktop bindings
    desktopNavItemList.forEach(function (navItem) {
        let _subItems = navItem.querySelector('.nav__sub-items');

        if (_subItems) {
            /**
             * On page load, we must reset all active states in the nav since browsers cache views.
             * We must also attach listeners to nav items that have sub items
             * so that we can apply active states to it when it is meant to show its sub items
             */
            $(navItem).removeClass('active');
            $(navItem).on('click', function () {
                // Determine if the navItem is active on click
                let _isActive = this.classList.contains('active');

                if (!navTint.classList.contains('active')) {
                    utils.toggleActive(navTint);
                } else if (_isActive) {
                    utils.toggleActive(navTint);
                }

                // De-activate all other
                deactivateAll();

                // If the clicked element is already active, then we are
                if (!_isActive) {
                    utils.toggleActive(this);
                }
            });
        }
    });

    $(navTint).on('click', function () {
        utils.toggleActive(navTint);
        deactivateAll();
    });

    // Mobile bindings
    mobileNavItemList.forEach(function (navItem) {
        let _subItems = navItem.querySelector('.nav__sub-items');

        if (_subItems) {
            /**
             * On page load, we must reset all active states in the nav since browsers cache views.
             * We must also attach listeners to nav items that have sub items
             * so that we can apply active states to it when it is meant to show its sub items
             */
            $(navItem).removeClass('active');
            $(navItem).on('click', function () {
                // Toggle first
                utils.toggleActive(this);

                // Do animation
                // Hidden > Active
                if (this.classList.contains('active')) {
                    _subItems.style.display = 'block';
                    setTimeout(function () {
                        _subItems.style.height = utils.getElementAutoHeight(_subItems) + 'px';
                    }, 100)
                }
                // Active > Hidden
                else {
                    _subItems.style.height = utils.getElementAutoHeight(_subItems) + 'px';
                    setTimeout(function () {
                        _subItems.style.height = '0';
                    }, 100);
                }
            });
        }
    });
}


/**
 *
 * @param event
 */
function clickOff(event) {

    // If there is an event provided, then check to see if it's target exists within
    // the navs
    if (event && desktopNav && (desktopNav.contains(event.target) || mobileNav.contains(event.target))) {
        return;
    }

    deactivateAll();
}

/**
 * Deactivates all active states for nav items
 */
function deactivateAll() {
    // Deactivate all desktop nav links
    desktopNavItemList.forEach(function (navItem) {
        // Deactivate all nav items that have the active class
        if (navItem.classList.contains('active')) {
            utils.toggleActive(navItem);
        }
    });

    // Deactivate all mobile nav links
    mobileNavItemList.forEach(function (navItem) {
        // Deactivate all nav items that have the active class
        if (navItem.classList.contains('active')) {
            utils.toggleActive(navItem);
        }
    });
}

/**
 * This function handles the toggling of the nav in mobile view
 */
function mobileNavToggle(event) {
    event.stopPropagation();

    // Toggle the active state and do an animation
    utils.toggleActive(mobileMenuToggle);
    if (window.innerWidth <= screenWidth) {
        scrollDownToHide.forEach(function (element) {
            element.style.setProperty("opacity", "0", "important");
        });
    }
    // Hidden > Active
    if (mobileMenuToggle.classList.contains('active')) {
        utils.lockBody(true);
        stopScroll();

        mobileMenu.style.display = 'block';

        // Timeout is here for firefox. Without it, the height animation fails to run
        setTimeout(function () {
            mobileMenu.style.height = '100vh';
        }, 10);
    }
    // Active > Hidden
    else {
        let eventClose = new CustomEvent('closeAccordion');
        window.dispatchEvent(eventClose);
        mobileMenu.style.height = '';
        utils.lockBody(false);
        setTimeout(function () {
            mobileMenu.style.display = '';
            stopScroll();
            startScroll();
        }, 250);
    }
}

/**
 *
 * @param event
 */
function mobileNavTransitionEnd(event) {
    // When closing the nav__items element on the mobile view, it should
    // reset all other open items
    if (event.propertyName === 'height'
        && event.target.classList.contains('nav__items')
        && event.target.clientHeight === 0) {
        mobileMenu.style.display = '';
        mobileMenu.style.height = '';

        // Deactivate all nav items
        deactivateAll();

        // Deactivate all nav sub-items
        mobileNavItemList.forEach(function (elem) {
            let _navSubMenu = elem.querySelector('.nav__sub-items');
            if (_navSubMenu) {
                _navSubMenu.style.display = '';
                _navSubMenu.style.height = '';
            }
        })
    }
}

/**
 *  This function handles the resize event for the navigation bar
 */
function navOnResize() {
    // If the mobile nav was active when resizing to desktop view, then undo the body lock and close the menu
    if (window.innerWidth > utils.mediumScreenWidth && mobileMenuToggle.classList.contains('active')) {
        utils.toggleActive(mobileMenuToggle);
        utils.lockBody(false);

        mobileMenu.style.display = '';
        mobileMenu.style.height = '';
    }

    stopScroll();
    startScroll();
}

function startScroll() {
    if (window.innerWidth <= screenWidth) {
        handler = function () {
            if (!scrolling) {
                scrolling = true;
                (!window.requestAnimationFrame)
                    ? setTimeout(autoHideHeader, 250)
                    : requestAnimationFrame(autoHideHeader);
            }
        };
        document.addEventListener('scroll', handler, false);
        handler();
    } else {
        handler = function () {
            window.requestAnimationFrame(function () {
                if ($(window).scrollTop() >= 1) {
                    $(nav).addClass('nav__sticky');
                } else {
                    $(nav).removeClass('nav__sticky');
                }
                if (navContact && $(window).scrollTop() >= navBreak) {
                    if (navDealership) {
                        navDealership.style.position = 'fixed';
                        navDealership.style.top = -navBreak + 'px';
                    } else {
                        nav.style.position = 'fixed';
                        nav.style.top = -navBreak + 'px';
                    }
                    if (navLogos) {
                        $(navLogos).find('.u-stack--left .logo').addClass('shrink-left');
                        $(navLogos).find('.u-stack--right .logo').addClass('shrink-right');
                    }
                } else if (navContact) {
                    if (navDealership) {
                        navDealership.style.position = 'absolute';
                        navDealership.style.top = 0;
                    } else {
                        nav.style.position = 'absolute';
                        nav.style.top = 0;
                    }
                    if (navLogos) {
                        $(navLogos).find('.u-stack--left .logo').removeClass('shrink-left');
                        $(navLogos).find('.u-stack--right .logo').removeClass('shrink-right');
                    }
                }
            });
        };
        document.addEventListener('scroll', handler, false);
        handler();
    }
}

function stopScroll() {
    document.removeEventListener('scroll', handler, false);
}

//set scrolling variables
let scrolling = false,
    previousTop = 0,
    scrollDelta = 50,
    scrollOffset = 150;


function autoHideHeader() {
    let currentTop = $(window).scrollTop();

    checkSimpleNavigation(currentTop);
    previousTop = currentTop;
    scrolling = false;
}

function checkSimpleNavigation(currentTop) {
    if (previousTop - currentTop > scrollDelta || currentTop < 200) {
        //if scrolling up...
        scrollUpToHide.forEach(function (element) {
            element.classList.remove('slideOutUp');
            element.classList.add('slideInDown');
        });
        scrollDownToHide.forEach(function (element) {
            element.classList.remove('slideOutDown');
            element.classList.add('slideInUp');
        });
    } else if (currentTop - previousTop > 1 && currentTop > scrollOffset) {
        //if scrolling down...
        scrollUpToHide.forEach(function (element) {
            element.classList.remove('slideInDown');
            element.classList.add('slideOutUp');
        });
        scrollDownToHide.forEach(function (element) {
            element.classList.remove('slideInUp');
            element.classList.add('slideOutDown');
        });
    }
}

if (desktopNav || mobileNav) {
    // Initialisation
    navItemBindClick();

// Event Listeners
    $(window).on('resize', navOnResize);
    $(mobileNav).on('transitionend', mobileNavTransitionEnd);
    if (utils.isMobile()) {
        $(mobileMenuToggle).on('click', mobileNavToggle);
        $(mobileNavButton).on('click', mobileNavToggle);
        mobileNavScrollTo.forEach(function (element) {
            $(element).on('click', mobileNavToggle);
        });
    } else {
        $(window).on('click', clickOff);
        $(mobileMenuToggle).on('click', mobileNavToggle);
        $(mobileNavButton).on('click', mobileNavToggle);
        mobileNavScrollTo.forEach(function (element) {
            $(element).on('click', mobileNavToggle);
        });
    }


    window.addEventListener('startScroll', function () {
        startScroll();
    }, false);


    window.addEventListener('stopScroll', function () {
        stopScroll();
    }, false);

    stopScroll();
    startScroll();
}