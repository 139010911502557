/**
 *
 * @author Joey
 */
const utils = require('./utils');
const dealerships = require("./dealerships");

const $contactBar = document.querySelectorAll('[scrollDownToHide]');

const initialiseAccordions = function () {
    $('.accordion')
        .each(function (index, accordion) {
            let $accordion = $(accordion);

            // Listen for the click event specifically on the hidden input.
            // The click event will get triggered by clicking the label.
            let $accordionControl = $('.accordion__control');

            if
            ($accordionControl) {
                $accordionControl
                    .off('click')
                    .on('click', function () {
                        let $accordion = this.parentNode;
                        let $accordion_control = $accordion.querySelector('.accordion__control');
                        let $accordion_content = $accordion.querySelector('.accordion__content');
                        let accordion_height = null;
                        // Checks if the accordion is not from the footer to animate it into its new position
                        if ($accordion.querySelector('label').getAttribute('for').indexOf('footer') === -1) {
                            $contactBar.forEach(function (element) {
                                element.style.setProperty("opacity", "0", "important");
                            });
                        }
                        // On click, wait for the accordion checkbox to change state
                        if ($accordion_control.checked) {
                            if ($($accordion).find('.accordion__switch--card > .sub-label')) {
                                $($accordion).find('.accordion__switch--card').css('padding-bottom', 0);
                                $($accordion).find('.accordion__switch--card > .sub-label').addClass('fade-out');
                                $($accordion).find('.accordion__switch--card > .sub-label').removeClass('fade-in');
                            }
                            accordion_height = utils.getElementAutoHeight($accordion_content);
                            $accordion_content.style.height = accordion_height + 'px';
                            utils.fireEvent('openAccordion');
                        } else {
                            accordion_height = utils.getElementAutoHeight($accordion_content);
                            $accordion_content.style.height = accordion_height + 'px';
                            utils.fireEvent('closeAccordion');
                            // Timeout is to allow for the height to be applied before animating down to 0
                            // Firefox is still experiencing issues at times
                            setTimeout(function () {
                                $accordion_content.style.height = '0';
                            }, 10);
                            if ($($accordion).find('.accordion__switch--card > .sub-label')) {
                                setTimeout(function () {
                                    $($accordion).find('.accordion__switch--card > .sub-label').removeClass('fade-out');
                                    $($accordion).find('.accordion__switch--card > .sub-label').addClass('fade-in');
                                    $($accordion).find('.accordion__switch--card').css('padding-bottom', 1.5 + 'rem');
                                }, 150);
                            }
                        }
                        if($accordion_control.getAttribute('id')){
                            const $signatureForm = $('[form-type="signature"]').find('form');
                            dealerships.setDealershipSelect($signatureForm.find('[dealership-select]'), {
                                listAll: true,
                                filters: [{field: 'emailSignatureDisplay', terms: true}]
                            });
                        }
                    });
            }

            $accordion
                .off('transitionend')
                .on('transitionend', function (event) {
                    // We only want to be listening for height changes
                    if (event.propertyName === 'height') {
                        // Check if the transition end event is for the accordion content, then check to see
                        // if the target node is contained within this accordion context
                        if (event.target.classList.contains('accordion__content') && this.contains(event.target)) {
                            // If it is then we just check to see if the control is checked
                            let $accordion_control = this.querySelector('.accordion__control');
                            let $accordion_content = this.querySelector('.accordion__content');

                            if ($accordion_control.checked) {
                                $accordion_content.style.height = 'auto';
                            }
                        }
                    }
                });

            // Because transitions are disabled while the page is loading we need to make
            // sure that accordions that are initialised in an open state have an automatic height
            // ('auto') to accommodate for dynamic content
            setTimeout(function () {
                let $accordion_control = $accordion.find('.accordion__control');
                let $accordion_content = $accordion.find('.accordion__content');

                if ($accordion_control && $accordion_control.prop('checked')) {
                    $accordion_content.css('height', 'auto');
                }
            }, 10)
        });
};

const initialiseExpanders = function () {
    $('.expand--list')
        .each((index, expander) => {
            let $controller = $(expander).find('.expand__control');
            let $content = $(expander).find('.expand__content');
            let $itemBreak = $content.find('[hide="after"]');
            let $firstItem = $content.find('.filter__list-item');
            let visibleHeight = $firstItem.height() * (($itemBreak.index() !== -1 ? $itemBreak.index() : $firstItem.siblings().length) + 1);
            let invisibleHeight = $firstItem.height() * ($itemBreak.siblings().length + 1);

            if ($content.attr('ticked') !== "true") {
                //sets the max height for the content to display
                $content.css('max-height', visibleHeight);
            }

            $controller.click(() => {
                $content.css('max-height', invisibleHeight);
                $content.attr('ticked', 'true');
                $contactBar.forEach(function (element) {
                    element.style.setProperty("opacity", "0", "important");
                });
                if ($controller[0].checked) {
                    utils.fireEvent('openAccordion');
                }
            });
        });
};

initialiseAccordions();
initialiseExpanders();

$(document.body)
    .on('reload_accordion', function () {
        initialiseAccordions()
    });

module.exports = {
    initialiseAccordions: initialiseAccordions,
    initialiseExpanders: initialiseExpanders
};
