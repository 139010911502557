const formUtil = require('./form-util');
const modals = require('./modals');

const $signatureForm = $('[form-type="signature"]').find('form');
const $signatureArea = $('.signature__area');

let signatureHTML = '';

$('[copy-to-clipboard]').on('click', function () {
    copyFormatted(signatureHTML);

    let button = $(this);

    button.css('opacity', 0.5).text('Content Copied!');
    setTimeout(function () {
        button.css('opacity', 1).text('Copy to Clipboard');
    }, 1000)
});

//Selects and copies signature to the clipboard as rich text
function copyFormatted(html) {
    // Create container for the HTML
    let container = document.createElement('div');
    container.innerHTML = html;

    // Hide element
    container.style.position = 'fixed';
    container.style.pointerEvents = 'none';
    container.style.opacity = 0;

    // Mount the iframe to the DOM to make `contentWindow` available
    document.body.appendChild(container);

    // Copy to clipboard
    window.getSelection().removeAllRanges();

    let range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);

    document.execCommand('copy');
    document.body.removeChild(container);
}

//Generates signature based on data provided
function generateSignature() {
    let selectForm = $signatureForm;

    let $name = selectForm.find('[signature-name]');
    let $number = selectForm.find('[signature-phone]');
    let $fax = selectForm.find('[signature-fax]');
    let $email = selectForm.find('[signature-email]');
    let $role = selectForm.find('[signature-role]');
    let $dealershipName = selectForm.find('input[name=DEALERSHIPNAME]');
    let $dealershipAddress = selectForm.find('[dealership-address-sales]');
    let $dealershipPhone = selectForm.find('[dealership-phone]');
    let $dealershipWebsite = selectForm.find('[dealership-website]');
    let $dealershipLicense = selectForm.find('[dealership-license]');
    let $dealershipLogo = selectForm.find('[dealership-logo] > img');
    let $includeAbout = selectForm.find('[signature-include-about]');
    let $includeService = selectForm.find('[signature-include-service]');
    let $dealershipServiceAddress = selectForm.find('input[name=DEALERSHIPSERVICEADDRESS]');
    let $dealershipServicePhone = selectForm.find('input[name=DEALERSHIPSERVICEPHONE]');

    let faxHTML = '';
    let emailHTML = '';
    let phoneHTML = '';
    let numberHTML = '';
    let dealershipLogoHTML = '';
    let licenseHTML = '';

    let salesHeadingHTML = $includeService.prop('checked') && ($dealershipServicePhone?.val() || $dealershipServiceAddress?.val()) ?
        '    <tr>' +
        '        <td style="font-weight: bold; font-family: Helvetica; font-size: 12px;">Sales</td>' +
        '    </tr>' : '';

    let serviceNumberHTML = $includeService.prop('checked') && $dealershipServicePhone?.val() && $dealershipPhone.text() !== '-' ?
        '    <tr>' +
        '        <td style="font-family: Helvetica, sans-serif; font-size: 12px;"><span style="font-weight: bold;">T&nbsp;</span>' + $dealershipServicePhone.val() + '</td>' +
        '    </tr>' : ''

    let serviceHTML = $includeService.prop('checked') && ($dealershipServicePhone?.val() || $dealershipServiceAddress?.val()) ?
        '    <tr>' +
        '        <td style="font-weight: bold; font-family: Helvetica; font-size: 12px;">Service</td>' +
        '    </tr>' +
        '    <tr>' +
        '        <td style="font-family: Helvetica, sans-serif; font-size: 12px;">' + $dealershipServiceAddress?.val() + '</td>' +
        '    </tr>' +
        serviceNumberHTML : '';

    let aboutHTML = $includeAbout.prop('checked') ?
        '<tr>' +
        '   <td style="padding-bottom: 8px; font-family: Helvetica; font-size: 10px; padding-top: 8px";>' +
        '       <span style="font-weight: bold; font-style: italic;">OUR VISION&nbsp;</span>' +
        '       <br>' +
        '       <span style="font-style: italic;">To be recognised as the motor industry\'s leading and most trusted family dealer, enhancing the lives of our customers, our people, and the communities we serve.</span>' +
        '   </td>' +
        '</tr>' +
        '<tr>' +
        '   <td style="padding-bottom: 16px; font-family: Helvetica; font-size: 10px;">' +
        '       <span style="font-weight: bold; font-style: italic;">OUR MISSION&nbsp;</span>' +
        '       <br>' +
        '       <span style="font-style: italic;">To build lasting relationships by delivering excellent brand and service experiences.</span>' +
        '   </td>' +
        '</tr>' : '';

    let disclaimerHTML =
        '<tr>\n' +
        '      <td style="font-family: Helvetica; font-size: 10px; color: #707070;"><span\n' +
        '      style="font-weight: bold">IMPORTANT NOTICE&nbsp;</span>Please consider the environment before printing this e-mail. Unless otherwise stated, this email and any attachments are confidential. If received in error, please remove both from your system and inform the sender by return email. Unauthorized amendment, copying or distribution is prohibited. Any views expressed in this email are those of the individual sender, except where the sender expressly, and with authority, states them to be the views of the Company. The Company liability in connection with transmitting, unauthorized access to, or viruses in this email and its attachments, is limited to re-supplying this message and its attachments. ' +
        '      </td>\n' +
        '</tr>\n';

    const separatorHTML = '<span style="font-weight: bold; font-family: Helvetica, sans-serif; font-size: 12px">&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>';

    if ($fax && $fax.val() !== '') {
        faxHTML = '<span style="font-weight: bold;">F&nbsp;</span>' + $fax.val();
    } else {
        faxHTML = '';
    }

    if ($dealershipPhone && $dealershipPhone.text() && $dealershipPhone.text() !== '—') {
        phoneHTML = '<span style="font-weight: bold;">T&nbsp;</span>' + $dealershipPhone.text();
    } else {
        phoneHTML = '';
    }

    if ($number && $number.val()) {
        numberHTML = '<span style="font-weight: bold;">M&nbsp;</span>' + $number.val();
    } else {
        numberHTML = '';
    }

    let contactHTML = '';
    let contactList = [];

    if (phoneHTML) contactList.push(phoneHTML);
    if (numberHTML) contactList.push(numberHTML);
    if (faxHTML) contactList.push(faxHTML);

    if (contactList.length) {
        contactHTML =
            '    <tr>' +
            '        <td style="font-family: Helvetica, sans-serif; font-size: 12px;">' + contactList.join(separatorHTML) +
            '        </td>' +
            '    </tr>';
    }

    if ($email && $email.val()) {
        emailHTML = '<span style="font-weight: bold; font-family: Helvetica, sans-serif; font-size: 12px">&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span><a href="mailto:' + $email.val() + '" style="color: #3C78BC; text-decoration: none">' + $email.val() + '</a>';
    } else {
        emailHTML = '';
    }

    if ($dealershipLogo && $dealershipLogo.length !== 0) {
        dealershipLogoHTML =
            '    <tr>' +
            '        <td style="border-top: 1px solid #A5AFB5; padding-top: 18px;">' +
            '            <img width="200" height="" style="display: block; width: 12.5em; height: auto;"' +
            '                 src="' + $dealershipLogo[0].src + '"/>' +
            '        </td>' +
            '    </tr>';
    } else {
        dealershipLogoHTML =
            '    <tr>' +
            '        <td style="border-top: 1px solid #A5AFB5; display: block;">' +
            '        </td>' +
            '    </tr>';
    }

    if ($dealershipLicense && $dealershipLicense.text() !== '—') {
        licenseHTML =
            '    <tr>' +
            '        <td style="font-family: Helvetica, sans-serif; font-size: 10px; color: #707070; padding-top: 16px">Lic. No. ' + $dealershipLicense.text() + '</td>' +
            '    </tr>';
    } else {
        licenseHTML =
            '    <tr style="padding-top: 16px; display: block;">' +
            '    </tr>';
    }

    signatureHTML =
        '<table style="width: auto;">\n' +
        '    <tr>' +
        '        <td style="font-family: Helvetica, sans-serif; font-size: 13px;">Regards,</td>' +
        '    </tr>' +
        '    <tr>' +
        '        <td style="font-weight: bold; font-family: Helvetica; font-size: 13px; padding-top: 8px;">' + $name.val() + '</td>' +
        '    </tr>' +
        '    <tr>' +
        '        <td style="font-family: Helvetica, sans-serif; font-size: 13px; padding-bottom: 16px;">' + $role.val() + '</td>' +
        '    </tr>' +
        dealershipLogoHTML +
        '    <tr>' +
        '        <td style="font-weight: bold; font-family: Helvetica; font-size: 12px; padding-top: 16px;">' + $dealershipName.val() + '</td>' +
        '    </tr>' +
        salesHeadingHTML +
        '    <tr>' +
        '        <td style="font-family: Helvetica, sans-serif; font-size: 12px;">' + $dealershipAddress.text() + '</td>' +
        '    </tr>' +
        contactHTML +
        serviceHTML +
        '    <tr>' +
        '        <td style="font-family: Helvetica, sans-serif; font-size: 12px;"><a href="http://' + $dealershipWebsite.text() + '" style="color: #3C78BC; text-decoration: none">' + $dealershipWebsite.text() + '</a>' + emailHTML +
        '        </td>' +
        '    </tr>' +
        aboutHTML +
        licenseHTML +
        disclaimerHTML +
        '</table>';

    $signatureArea.children().remove();
    $signatureArea.append(signatureHTML);
}

formUtil.catchSubmit($signatureForm, function (invalid) {
    if (!invalid) {
        modals.toggleModal('emailSignature');
        generateSignature();
    }
});