const $errorMessage = $('.toast');

$errorMessage
    .off()
    .on('animationend', () => {
        if ($errorMessage.hasClass('active')) {
            $errorMessage.removeClass('active');
        }
    });

window.addEventListener('showerrortoast', (event) => {
    $errorMessage.find('label').text(event.detail.message);
    $errorMessage.addClass('active');
});