const $maoInput = $('[mao]');

$maoInput.forEach((input) => {
    let $elem = $(input);
    let $button = $elem.parents('form').find('.form__submit button');
    $elem.on('keyup', (() => {
        if ($elem.val()) {
            $button.html('Make An Offer');
            $button.addClass('button--pink');
        } else {
            $button.html('Send Enquiry');
            $button.removeClass('button--pink');
        }
    }));
})