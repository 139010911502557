/**
 * Checks whether the provided date is before a date
 * @param end Date() or String
 * @returns {boolean}
 */
Date.prototype.isBefore = function (end) {
    return Date.parse(this) <= Date.parse(end);
};

/**
 * Checks whether the provided date is before a date
 * @param start Date() or String
 * @returns {boolean}
 */
Date.prototype.isAfter = function (start) {
    return Date.parse(this) >= Date.parse(start);
};

/**
 * Checks whether the provided date is between two other provided dates
 * @param start Date() or String
 * @param end Date() or String
 * @returns {boolean}
 */
Date.prototype.isBetween = function (start, end) {
    return this.isBefore(end) && this.isAfter(start);
};

/**
 * Gets the timezone offset as an ISO8601 string suffix
 * @returns {string} 'Z' or '-06:00' or '+11:00'
 */
Date.prototype.getISOTimezoneOffset = function () {
    const offset = this.getTimezoneOffset();
    let offsetHrs = parseInt(Math.abs(offset / 60)),
        offsetMins = Math.abs(offset % 60),
        timezoneISO;

    if (offsetHrs < 10) offsetHrs = '0' + offsetHrs;
    if (offsetMins < 10) offsetMins = '0' + offsetMins;
    if (offset < 0) timezoneISO = '+' + offsetHrs + ':' + offsetMins;
    else if (offset > 0) timezoneISO = '-' + offsetHrs + ':' + offsetMins;
    else if (offset == 0) timezoneISO = 'Z';

    return timezoneISO;
};