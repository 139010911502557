const $starRating = $('.star-rating');

if ($starRating) {
    $starRating.each(function (i, el) {
        const $container = $(el);
        const $stars = $container.find('.rating');
        const $label = $container.find('.star-rating__label > span');
        const def = $label.data('label-default');

        $stars.each(function (i, el) {
            $(el).on('click', function (e) {
                $container.find('.rating__input').removeClass('invalid');
                $label.text($(e.target).data('label'));
            }).on('mouseover', function (e) {
                $label.text($(e.target).data('label'));
            }).on('mouseout', function () {
                let $checked = $container.find('input[type=radio]:checked');
                if ($checked[0]) {
                    $label.text($checked.data('label'));
                } else {
                    $label.text(def);
                }
            });
        });
    })
}