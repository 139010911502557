const utils = require('./utils');

const livechatButton = $('.button--livechat');
const buttonBadge = $('.livechat--badge');
let livechatContainer;

// Trigger the chat window
livechatButton.click(function () {
    try {
        window.LC_API.open_chat_window({ source: 'minimized' });
    } catch (e) {
        utils.lockBody(false);
    }
});

const lockBody = (bool) => {
    if (utils.isMobile()) {
        utils.lockBody(bool);
    }
};

// Check the window for an instance of the live chat api
const loading = setInterval(function () {
    livechatContainer = $('#chat-widget-container');
    if (window.LC_API && livechatContainer.length) {
        // Add additional meta tags to the iframe
        $('iframe').find('head').append('<meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width viewport-fit=cover">');

        // Events on chat minimised
        window.LC_API.on_chat_window_minimized = function () {
            setTimeout(function () {
                livechatContainer.removeClass('active');
                lockBody(false);
            }, 10);
        };

        // Events on chat opened
        window.LC_API.on_chat_window_opened = function () {
            buttonBadge.removeClass('active');
            // A slight delay allows for the original hidden chat bubble to fade out and for the body to lock properly
            setTimeout(function () {
                livechatContainer.addClass('active');
                lockBody(true);
            }, 10);
        };

        // Events on incoming message
        window.LC_API.on_message = function () {
            if (window.LC_API.chat_window_minimized()) {
                buttonBadge.addClass('active');
            }
        };

        clearInterval(loading);
    }
}, 500);

//adds a class to the different floating button to add enough space for the livechat button
if (livechatButton.length > 0 && window.innerWidth <= 812) {
    let $footer = $('.footer');
    let $registerButton = $('.button--mobile');
    let $filterButton = $('.inventory__button-fixed');
    let $filterButtonStick = $('.inventory__button-stick');

    $footer.addClass('livechat-padding');
    if ($registerButton) {
        $registerButton.addClass('livechat-padding');
    }
    if ($filterButton) {
        $filterButton.addClass('livechat-padding');
    }
    if ($filterButtonStick) {
        $filterButtonStick.addClass('livechat-padding');
    }
}