const $tabs = $('[tab-show]');
const $tabsSections = $('[tab-section]');

$tabs.on('click', function () {
    let $activeTab = $(this);
    if (!$activeTab.hasClass('active')) {
        $tabs.forEach((tab) => {
            $(tab).removeClass('active');
        });

        $activeTab.addClass('active');
        showSection($activeTab.attr('tab-show').trim());
    }
});

const showSection = (id) => {
    if ($tabsSections && $tabsSections.length > 0) {
        $tabsSections.forEach((section) => {
            let $section = $(section);
            $section.removeClass('active');
            if ($section.attr('tab-section').trim() === id) {
                $section.addClass('active');
            }
        });
    }
};