$(document).ready(function () {
    const initialiseSVGGradient = () => {
        $('.price--green').css('fill', 'url('+ document.URL + '#gradient-green)');
        $('.price--blue').css('fill', 'url('+ document.URL + '#gradient-blue)');
        $('.price--purple').css('fill', 'url('+ document.URL + '#gradient-purple)');
    };

    initialiseSVGGradient();

    $(document)
        .off('initialise-svg-gradient')
        .on('initialise-svg-gradient', () => {
            initialiseSVGGradient();
        });
});

