const analytics = require('./analytics');

const $form = $('.finance-calculator');

if ($form.length > 0) {

    const $loanAmount = $('[name="LOANAMOUNT"]');
    const $loanTerm = $('[name="LOANTERM"]');
    const $loanInterest = $('[name="LOANINTEREST"]');
    const $balloon = $('[name="BALLOONPAYMENT"]');
    const $calculatorOutput = $('[calculator-estimate]');
    const $stepper = $('.filter__stepper-button');
    const $balloonBtn = $('.balloon__link');
    const $balloonSection = $('.balloon__section');

    let analyticsSent = false;

    $stepper.forEach(function (el) {
        $(el).on('click', function () {
            getInputVal(this)
        });
    });

    $balloonBtn.on('click', function () {
        $balloonBtn.parent().addClass('u-display-none');
        $balloonSection.removeClass('u-display-none');
    });

    $(".finance-calculator input").on('keyup change', function () {
        calculate();
    });

    let getInputVal = function (input) {
        let direction = $(input).attr('data-direction');
        let $input = $(input).parent().find('input');
        let incrementBy = $input.attr('stepper');
        let fixed = $input.attr('fixed') || 2;
        if (direction === 'forward') {
            $input.val(Number(Number($input.val()) + Number(incrementBy)).toFixed(Number(fixed)));
        } else if (direction === 'backward') {
            $input.val(Number(Number($input.val()) - Number(incrementBy)).toFixed(Number(fixed)));
        }
        calculate();
    };

    let calculate = function () {
        valid();
        let $repaymentMethodValue = $form.find('[name="PAYMENTFREQUENCY"]:checked').val();
        let loanAmount = Number($loanAmount.val());
        let loanTerm = Number($loanTerm.val());
        let loanInterest = Number($loanInterest.val());
        let loanInterestRate = Number((loanInterest / Number($repaymentMethodValue) / 100));
        let balloon = Number($balloon.val());
        let n = loanTerm * Number($repaymentMethodValue);

        let output = (loanAmount - (balloon / (Math.pow((1 + loanInterestRate), n)))) * (loanInterestRate / (1 - (Math.pow(1 + loanInterestRate, (-n)))));

        if (output > 0 && isFinite(output)) {
            $calculatorOutput.html(output.toFixed(2));
            if (!analyticsSent) {
                analytics.trackGoogleEvent("finance", "Finance Calculator Used", window.location.href);
                analyticsSent = true;
            }
        } else {
            $calculatorOutput.html('–');
        }
    };

    let valid = function () {
        if ($loanAmount.val() < 0) {
            $loanAmount.val(0);
        }
        if ($loanTerm.val() < 0) {
            $loanTerm.val(1);
        }
        if ($loanInterest.val() < 0) {
            $loanInterest.val(0.0);
        }
        if ($balloon.val() < 0) {
            $balloon.val(0);
        }
    };

    calculate();
}