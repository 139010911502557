const $viewMore = $('[view]');
const $viewMoreButton = $('.view-more-button');

let updateHeight = ($el, height) => {
    $el.css('max-height', height + 'px !important');
    $el.next('.view-more-button').css('display', 'block');
};

$viewMore.each(function () {
    let $el = $(this);
    let height = parseInt($el.attr('view'));

    if ($el[0].scrollHeight > height) {
        $el.addClass('active');
        $el.attr('original-height', $el[0].scrollHeight);
        updateHeight($el, height);
    }
});

$viewMoreButton.on('click', function () {
    let $el = $(this);
    let $preEl = $el.prev('[view]');
    let viewBtn = $el.find('span');
    let hideHeight = parseInt($preEl.attr('view'));
    let showHeight = $preEl.attr('original-height');

    if (viewBtn.text() === 'Show Less') {
        updateHeight($preEl, hideHeight);
        viewBtn.text('Show More');
        $preEl.addClass('active');
    } else {
        $preEl.removeClass('active');
        updateHeight($preEl, (parseInt(showHeight) + 16));
        viewBtn.text('Show Less');
    }
});