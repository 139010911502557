const modal = require('./modals.js');
const utils = require("./utils");

$('.link.link--back--VDP')
    .on('click', function () {
        let baseURL = window.location.protocol + "//" + window.location.host + "/";
        if (document.referrer.indexOf(baseURL) !== -1) {
            return history.back();
        } else {
            document.location.href = "/";
        }
    });

addEventListener('popstate', (e) => {
    modal.MODAL_MAPPER.some(item => {
        if (window.location.hash.match(item.hash) && (e.target.location.href.includes('#'))) {
            history.back();
        }
    });
});