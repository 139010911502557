const container = document.getElementById('footer--grid');
const $window = $(window);
let colHeights = [0, 0, 0, 0];

if (container) {
    const updateFooter = () => {
        colHeights = [0, 0, 0, 0];
        for (let i = 0; i < container.children.length; i++) {
            let height = container.children[i].clientHeight;
            let order = colHeights.indexOf(Math.min(...colHeights));

            container.children[i].style.order = order;
            colHeights[order] += parseFloat(height);
        }

        // Plus one for rounding issue on some browsers
        container.style.height = Math.max(...colHeights) + 1 +'px';
    };

    updateFooter();

    $window.on('resize', function () {
        updateFooter();
    });
}