let footerMargin = 0;
const utils = require('./utils');

// Elements
let $contactBar, $contactBarSpace;

// Calc variables
let windowWidth, scrollTop, scrollTopPos, stickPos;
let $dealershipCardBody = $('.dealership-panel-body');

function init() {
    windowWidth = window.innerWidth;
    $contactBar = document.querySelectorAll('[scrollDownToHide]');
    $contactBarSpace = document.querySelector('.mobile-contact-bar-space');
}

function calc(scrollingDown, accordian, value) {
    if (accordian) {
        stickPos = $contactBarSpace.offsetTop + value;
    } else {
        stickPos = $contactBarSpace.offsetTop;
    }
    // Current scroll top
    scrollTop = $(window).scrollTop();
    if (scrollingDown) {
        // Position at which the contact bar switches between sticky & static
        scrollTopPos = stickPos - window.innerHeight + footerMargin;
    } else {
        // Position at which the contact bar switches between sticky & static
        scrollTopPos = stickPos + $contactBarSpace.offsetHeight - window.innerHeight + footerMargin;
    }
}

function toggleStick() {
    if (scrollTop > scrollTopPos) {
        $contactBar.forEach(function (element) {
            element.style.position = 'absolute';
            element.style.setProperty("transition", "padding, margin, opacity 4.5s");
            element.style.setProperty("transform", "translate3d(0, 0, 0)", "important");
            element.style.setProperty("margin-bottom", "0", "important");
            if (element.offsetHeight < 70 || element.classList[0] === "subheader") {
                element.style.top = (stickPos - 56) + 'px';
                element.style.setProperty("transition", "padding, opacity 0.5s");
                element.style.setProperty("padding-bottom", "1rem", "important");
            } else {
                element.style.top = stickPos + 'px';
                element.style.setProperty("padding-bottom", "0", "important");
            }
        });
    } else {
        $contactBar.forEach(function (element) {
            element.style.position = '';
            element.style.top = '';
            element.style.setProperty("margin-bottom", "", "important");
            element.style.setProperty("padding-bottom", "", "important");
            element.style.setProperty("transform", "", "important");
            element.style.transition = '';
        });
    }
}

if ($('.mobile-contact-bar [scrollDownToHide]').length > 0) {
    init();

    let lastScrollTop = 0;

    window.addEventListener("scroll", function () {
        window.requestAnimationFrame(() => {
            if (windowWidth <= utils.mediumScreenWidth) {
                let st = window.pageYOffset || document.documentElement.scrollTop;
                footerMargin = +window.getComputedStyle($contactBar[0]).marginBottom.replace('px', '');
                if ((st - lastScrollTop) < 81) {
                    if ($(window).scrollTop() > 81 && st > lastScrollTop) {
                        calc(true);
                    } else {
                        calc(false);
                    }
                    toggleStick();
                }
                lastScrollTop = st;
            }
        });
    });

    window.addEventListener('resize', function () {
        init();
    });

    window.addEventListener('openAccordion', function () {
        if (windowWidth <= utils.mediumScreenWidth) {
            setTimeout(function () {
                calc(true);
                toggleStick();
            }, 300);
            setTimeout(function () {
                $contactBar.forEach(function (element) {
                    element.style.setProperty("opacity", "", "important");
                });
            }, 500)
        }
    }, false);

    window.addEventListener('closeAccordion', function () {
        if (windowWidth <= utils.mediumScreenWidth) {
            setTimeout(function () {
                calc(false);
                toggleStick();
            }, 300);
            setTimeout(function () {
                $contactBar.forEach(function (element) {
                    element.style.setProperty("opacity", "", "important");
                });
            }, 500)
        }
    }, false);

    window.addEventListener('closeModal', function () {
        if (windowWidth <= utils.mediumScreenWidth) {
            calc(false);
            toggleStick();
            setTimeout(function () {
                $contactBar.forEach(function (element) {
                    element.style.setProperty("opacity", "", "important");
                });
            }, 500)
        }
    }, false);
}

if ($('#DDP').length > 0) {
    // Set the global enquiry button to open Dealership details form
    $('#global_enquiry').attr('toggle-modal', 'DDPModal');
}

if (($dealershipCardBody.length > 0) && (window.innerWidth > utils.mediumScreenWidth)) {
    $dealershipCardBody.css({'max-height': $dealershipCardBody.height() + 'px'});
}