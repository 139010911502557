const modals = require('./modals');
const formUtil = require('./form-util');
const $dealershipContainer = $('#dealershipDetails');
const $carousel = $('#carousel');

const d = 50;
const $window = $(window);

const medScreen = 812;

let startScroll = new CustomEvent('startScroll');
let stopScroll = new CustomEvent('stopScroll');

/**
 * Function to scroll the page to an element
 * @param $el Element to scroll to
 * @param duration Duration of the
 */
function smoothScroll($el, duration) {
    let $nav = 0;
    let $navCarousel = 0;
    if ($('.nav') && $('.nav').length > 0) {
        $nav = $('.nav')[0].offsetHeight + 16;
    }
    if ($('.nav-carousel-container') && $('.nav-carousel-container').length > 0) {
        $navCarousel = $('.nav-carousel-container')[0].offsetHeight;
    }
    let to = $el.offset().top - $nav + $navCarousel;
    scrollFrame(to, duration, function () {
        if (window.location.href) {
            history.pushState("", document.title, window.location.pathname);
        }

        if ($el.find('form')) {
            formUtil.focusFirst($el.find('form'));
        }
    });
}

function scrollFrame(to, duration, callback) {
    if (duration < 0) {
        if (window.innerWidth <= medScreen) {
            window.dispatchEvent(startScroll);
        }
        if (callback) {
            callback();
        }
        return;
    }
    let scrollTop = $window.scrollTop();
    let difference = to - scrollTop;
    setTimeout(() => {
        window.requestAnimationFrame(function () {
            if (window.innerWidth <= medScreen) {
                window.dispatchEvent(stopScroll);
            }
            window.scrollTo(0, scrollTop + difference * easeOutSine(d - duration));
            scrollFrame(to, duration - 1, callback);
        });
    }, 1);
}

function initScrollTo() {
    $('[scrollTo]')
        .click(function (e) {
            if (window.innerWidth <= medScreen) {
                window.dispatchEvent(stopScroll);
            }
            e.preventDefault();
            smoothScroll($('#' + $(this).attr('scrollTo')), d);
        });
}

let easeOutSine = function (t) {
    return 1 / 4 * Math.sin(t / d * (Math.PI / 2));
};

window.onload = function () {
    if (window.location.hash === '#contact-us') {
        if (window.innerWidth <= medScreen) {
            window.dispatchEvent(stopScroll);
        }
        smoothScroll($dealershipContainer, d);
    }
    if (window.location.hash === '#manager-program' && $("#manager-program").length > 0) {
        if (window.innerWidth <= medScreen) {
            window.dispatchEvent(stopScroll);
        }
        smoothScroll($("#manager-program"), d);
    }
    if (window.location.hash === '#sign-up-form' && $("#signUpForm").length > 0) {
        if (window.innerWidth <= medScreen) {
            window.dispatchEvent(stopScroll);
        }
        smoothScroll($("#signUpForm"), d);
    }
    if (window.location.hash === '#vehicle-enquiry' && $("#vehicleEnquiry").length > 0) {
        if (window.innerWidth <= medScreen) {
            window.dispatchEvent(stopScroll);
            modals.toggleModal("VDPModal");
        } else {
            smoothScroll($("#vehicleEnquiry"), d);
        }
    }
    if (window.location.hash === '#inventory' && $("#inventory_container").length > 0) {
        if (window.innerWidth <= medScreen) window.dispatchEvent(stopScroll);
        smoothScroll($("#inventory_container"), d);
    }
};

$('[href="/#contact-us"]').on("click", function () {
    setTimeout(function () {
        if (window.location.hash === '#contact-us') {
            if (document.readyState === 'complete') {
                if ($dealershipContainer.length > 0) {
                    if (window.innerWidth <= medScreen) {
                        window.dispatchEvent(stopScroll);
                    }
                    smoothScroll($dealershipContainer, d);
                }
            }
        }
    }, 300);
});

$('[href="/#sign-up-form"]').on("click", function () {
    setTimeout(function () {
        if (window.location.hash === '#sign-up-form') {
            if (document.readyState === 'complete') {
                if ($('#signUpForm').length > 0) {
                    if (window.innerWidth <= medScreen) {
                        window.dispatchEvent(stopScroll);
                    }
                    smoothScroll($('#signUpForm'), d);
                }
            }
        }
    }, 300);
});


if ($carousel.length > 0) {
    $carousel.find('a[href="/#contact-us"]').on("click", function () {
        setTimeout(function () {
            if (window.location.hash === '#contact-us') {
                if (document.readyState === 'complete') {
                    if ($dealershipContainer.length > 0) {
                        window.dispatchEvent(stopScroll);
                        if (window.innerWidth <= medScreen) {
                            smoothScroll($dealershipContainer, d);
                        }
                    }
                }
            }
        }, 300);
    });
}

initScrollTo();

window.addEventListener('reInitScrollTo', function () {
    initScrollTo();
});

module.exports = {
    smoothScroll,
    scrollFrame
};

$(document).ready(function () {
//prevents transitions from occuring while the DOM loads
    $('body').removeAttr('no-transition');
});